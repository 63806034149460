<template>
    <div v-loading="loading">
        <div class="card card-custom card-stretch gutter-b">
            <div class="card-header">
                <div class="card-title">
                    <span class="card-icon">
                        <i class="flaticon2-delivery-package text-primary"></i>
                    </span>
                    <h3 class="card-label"><b class="title-header">フォーム項目定義</b></h3>
                </div>
            </div>
            <div class="card-body">
                <el-form label-width="200px"
                         class="el-form-items">
                    <el-form-item prop="parent" label-width="0px">

                        <el-col :md="10">
                            <el-form-item class="item-fome-base form-item-start item_group_name" label="フォーム名" prop="form_id">
                                <el-select @change="getFormBase" class="w--10 bg-gray-item"
                                           v-model="form_id"
                                           placeholder="選択してください">
                                    <el-option
                                            v-for="(item, index) in form_base_select"
                                            :key="index"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                                <label v-if="errors['0.form_id']"
                                       class="text-danger text-left">
                                    {{ errors['0.form_id'][0] }}
                                </label>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                    <template>
                        <myTab type="border-card" :lazy="true" class="tab-design" v-model="activeName" @before-leave="loading = true" @tab-click="handleClick">
                            <tabPane v-for="number in totalItemForm" :key="number" :label="'ページ(' + number +')'" :name="number">
                                <div v-if="activeName == number" class="">
                                    <div v-for="(form_item, formindex) in form" :key="formindex">
                                        <div class="" :ref="'scroolError'+formindex">
                                            <div class="card-sub-title d-flex justify-content-between">
                                                <div class="d-flex">
                                                    <i @click="showItem(form_item) " v-if="form[formindex].toggle"
                                                       class="fas fa-angle-down pr-2 text-size-27"></i>
                                                    <i @click="showItem(form_item) " v-else
                                                       class="fas fa-angle-up pr-2 text-size-27"></i>
                                                    <h3 @click="showItem(form_item)" class="card-label">項目 {{getIncrement(form[formindex],formindex)}} {{ form[formindex].status == 0 ? '【未入力完了】' : '' }}</h3>
                                                </div>
                                                <div>
                                                    <button :disabled="disableForm" v-if="![...constants.SPECIAL_PROCESSING_KEY_UNIQUE_PASS].includes(form_item.special_processing)" @click.stop.prevent="copyaItem(form_item)" class="btn btn-copy-a-item text-white mr-4">コピー</button>
                                                    <button :disabled="disableForm" @click.stop.prevent="confirmDeteleItem(formindex)" class="btn btn-danger text-white btn-detele-item"> 削除</button>
                                                </div>
                                            </div>
                                            <div>
                                            </div>
                                            <div>
                                                <el-collapse-transition>
                                                    <div v-show="form_item.toggle">
                                                        <div class="transition-box pt-6">
                                                            <el-form-item prop="parent" label-width="0px">
                                                                <el-col :md="10">
                                                                    <el-form-item class="item-fome-base form-item-start" label="項目グループ名"
                                                                                  prop="item_group_name">
                                                                        <el-select  :disabled="!checkEditItem(form_item)" class="w--10 bg-gray-item"
                                                                                    v-model="form[formindex].item_group_name"
                                                                                    placeholder="選択してください">
                                                                            <el-option
                                                                                v-for="(item, index) in form_group_name"
                                                                                :key="index"
                                                                                :label="item"
                                                                                :value="parseInt(index)">
                                                                            </el-option>
                                                                        </el-select>
                                                                        <div class="text-initial">
                                                                            <label v-if="errors[formindex+'.item_group_name']"
                                                                                   class="text-danger text-left">
                                                                                {{ errors[formindex + '.item_group_name'][0] }}
                                                                            </label>
                                                                        </div>
                                                                    </el-form-item>
                                                                    <div>

                                                                    </div>
                                                                </el-col>
                                                                <el-col :md="10">
                                                                    <el-form-item class="item-center item-fome-base" label="項目グループ名（中）"
                                                                                  prop="input_method">
                                                                        <el-select :disabled="!checkEditItem(form_item)"
                                                                                   class="w--10 bg-gray-item"
                                                                                   v-model="form[formindex].item_group_name_middle"
                                                                                   placeholder="選択してください">
                                                                            <el-option label="なし" value=" "></el-option>
                                                                            <el-option
                                                                                v-for="(item, index) in item_group_name_middle_list"
                                                                                :key="index"
                                                                                :label="item"
                                                                                :value="parseInt(index)">
                                                                            </el-option>
                                                                        </el-select>
                                                                        <div class="text-initial">
                                                                            <label v-if="errors[formindex+'.item_group_name_middle']"
                                                                                   class="text-danger text-left">
                                                                                {{ errors[formindex + '.input_method'][0] }}
                                                                            </label>
                                                                        </div>
                                                                    </el-form-item>
                                                                </el-col>
                                                            </el-form-item>

                                                        </div>
                                                        <div class="transition-box">
                                                            <el-form-item prop="parent" label-width="0px">
                                                                <el-col :md="10">
                                                                    <el-form-item class="item-fome-base" :class="{'form-item-start': form[formindex].input_method != constants.FIELDS.LABEL}" label="ラベル名"
                                                                                  prop="label_name">
<!--                                                                        <el-input  :disabled="!checkEditItem(form_item)" maxlength="80" class="bg-gray-item" v-model="form[formindex].label_name"></el-input>&ndash;&gt;-->
                                                                        <div class="bg-gray-item el-input">
                                                                            <input :class="disableForm ? 'not-allowed-form-item' : ''" type="text" autocomplete="off"  maxlength="80" class="el-input__inner" :disabled="!checkEditItemLbName(form_item)" @change="filterCalcItem(form_item)" v-model.lazy="form[formindex].label_name"  placeholder="ラベル名を入力">
                                                                        </div>
                                                                        <div class="text-initial">
                                                                            <label v-if="errors[formindex+'.label_name']"
                                                                                   class="text-danger text-left">
                                                                                {{ errors[formindex + '.label_name'][0] }}
                                                                            </label>
                                                                        </div>
                                                                    </el-form-item>
                                                                </el-col>
                                                                <el-col :md="10">
                                                                    <el-form-item class="item-center item-fome-base form-item-start" label="入力方式"
                                                                                  prop="input_method">
                                                                        <el-select :disabled="!checkEditItem(form_item)" @change="changeInputMethod(form_item,true,formindex)"
                                                                                   class="w--10 bg-gray-item"
                                                                                   v-model="form[formindex].input_method"
                                                                                   placeholder="選択してください">
                                                                            <el-option
                                                                                v-for="item in input_method"
                                                                                :key="item.value"
                                                                                :label="item.label"
                                                                                :value="item.value">
                                                                            </el-option>
                                                                        </el-select>
                                                                        <div class="text-initial">
                                                                            <label v-if="errors[formindex+'.input_method']"
                                                                                   class="text-danger text-left">
                                                                                {{ errors[formindex + '.input_method'][0] }}
                                                                            </label>
                                                                        </div>
                                                                    </el-form-item>
                                                                </el-col>
                                                            </el-form-item>
                                                        </div>

                                                        <div class="transition-box">
                                                            <el-form-item prop="parent" label-width="0px">
                                                                <el-col :md="10">
                                                                    <el-form-item class="item-fome-base" label="入力チェック"
                                                                                  prop="check_method">
                                                                        <el-select :disabled="!checkEditItem(form_item,true)" @change="changeCheckMethod(form_item)"
                                                                                   class="w--10 bg-gray-item"
                                                                                   v-model="form[formindex].check_method"
                                                                                   placeholder="選択してください" multiple>
                                                                            <el-option label="なし" value=" "></el-option>
                                                                            <el-option
                                                                                v-for="(item, index) in form[formindex].check_method_list"
                                                                                :key="index"
                                                                                :label="item.label"
                                                                                :value="item.value">
                                                                            </el-option>
                                                                        </el-select>
                                                                        <div class="text-initial">
                                                                            <label v-if="errors[formindex+'.check_method']"
                                                                                   class="text-danger text-left">
                                                                                {{ errors[formindex + '.check_method'][0] }}
                                                                            </label>
                                                                        </div>
                                                                    </el-form-item>
                                                                </el-col>
                                                                <el-col :md="10" v-if="[3,4].includes(form_item.input_method) && form_base_selected.special_item > 0">

                                                                    <el-col v-for="(form_related_item, form_related_index) in form[formindex].related_items" :key="form_related_index" :md="24">
                                                                        <el-col :md="22">
                                                                            <el-form-item class="item-center item-fome-base" :label="'選択式設問の選択 ('+(form_related_index+1) + ')'"
                                                                                                                 prop="related_items">
                                                                            <el-select @change="changeRelatedItem($event,form_item,form_related_index)"
                                                                                       v-model="form[formindex].related_items[form_related_index]"
                                                                                       class="w--10 bg-gray-item"
                                                                                       placeholder="選択してください" multiple>
                                                                                <el-option label="なし" value=" "></el-option>
                                                                                <el-option
                                                                                    v-for="(item, index) in listQuestionShow"
                                                                                    :key="index"
                                                                                    :label="item.label_name"
                                                                                    :value="item.item_id"
                                                                                    :disabled="disableListQuestion(item,form[formindex])">
                                                                                </el-option>
                                                                            </el-select>
                                                                            <div class="text-initial">
                                                                                <label v-if="errors[formindex+'.related_items.'+form_related_index+'.0'] || errors[formindex+'.related_items.'+form_related_index]"
                                                                                       class="text-danger text-left">
                                                                                    {{ errors[formindex + '.related_items.'+form_related_index+'.0'] ? errors[formindex + '.related_items.'+form_related_index+'.0'][0] : '' }}
                                                                                    <br>
                                                                                    {{ errors[formindex + '.related_items.'+form_related_index] ? errors[formindex + '.related_items.'+form_related_index][0] : ''}}
                                                                                </label>
                                                                            </div>
                                                                        </el-form-item>
                                                                        </el-col>
                                                                        <el-col :md="2">
                                                                            <el-button v-if="form_related_index == 0" @click="addNewItemRelatedItem(form[formindex])" class="btn-add-item btn-all-p w---100 btn-primary-and-text">+</el-button>
<!--                                                                            <el-button v-else @click="deleteItemRelatedItem(form[formindex],form_related_index)" class="btn-add-item btn-all-p w-&#45;&#45;100 btn-primary-and-text">-</el-button>-->
                                                                            <button v-else @click.stop.prevent="deleteItemRelatedItem(form[formindex],form_related_index)" class="btn btn-danger text-white btn-detele-item btn-delete-related"> x</button>
                                                                        </el-col>
                                                                    </el-col>
                                                                </el-col>
                                                            </el-form-item>
                                                        </div>
                                                        <div class="transition-box">
                                                            <el-form-item prop="parent" label-width="0px">
                                                                <el-col :md="10">
                                                                    <el-form-item class="item-fome-base form-item-start" label="表示順"
                                                                                  prop="display_order">
                                                                        <el-input :disabled="!checkEditItem(form_item)" class="bg-gray-item" min="1" type="number" onkeypress="return this.value.length < 4;"
                                                                                  oninput="if(this.value.length>=3) { this.value = this.value.slice(0,4); }"
                                                                                  v-model="form[formindex].display_order"></el-input>
                                                                        <div class="text-initial">
                                                                            <label v-if="errors[formindex+'.display_order']"
                                                                                   class="text-danger text-left">
                                                                                {{ errors[formindex + '.display_order'][0] }}
                                                                            </label>
                                                                        </div>
                                                                    </el-form-item>
                                                                </el-col>
                                                                <el-col :md="10" v-if="[3,4].includes(form_item.input_method) && form_base_selected.special_item > 0 ">
                                                                    <el-form-item v-for="(form_answer_action, answer_action_index) in form[formindex].answer_action" :key="answer_action_index" class="item-center item-fome-base"
                                                                                  :label="'選択式設問有効回 ('+(answer_action_index+1) + ')'"
                                                                                  prop="answer_action">
                                                                        <el-select
                                                                            @change="changeAnswerAction($event,form_item,answer_action_index)"
                                                                            v-model="form[formindex].answer_action[answer_action_index]"
                                                                            class="w--10 bg-gray-item"
                                                                            placeholder="選択してください" multiple>
                                                                            <el-option label="なし" value=" "></el-option>
                                                                            <el-option
                                                                                v-for="(item, index) in form_item.list_answer_show"
                                                                                :key="index"
                                                                                :label="item"
                                                                                :value="item">
                                                                            </el-option>
                                                                        </el-select>
                                                                        <div class="text-initial">
                                                                            <label v-if="errors[formindex+'.answer_action.'+answer_action_index+'.0']"
                                                                                   class="text-danger text-left">
                                                                                {{ errors[formindex + '.answer_action.'+answer_action_index+'.0'][0] }}
                                                                            </label>
                                                                        </div>
                                                                    </el-form-item>
                                                                </el-col>
                                                            </el-form-item>
                                                        </div>
                                                        <div class="transition-box">
                                                            <el-form-item prop="parent" label-width="0px">
                                                                <el-col :md="10">
                                                                    <el-form-item class="item-fome-base" label="特殊処理"
                                                                                  prop="special_processing">
                                                                        <el-select :disabled="!checkEditItem(form_item)" @change="changeSpacialProcessing(form_item)" class="w--10 bg-gray-item"
                                                                                   v-model="form[formindex].special_processing"
                                                                                   placeholder="選択してください">
                                                                            <el-option label="なし" value=" "></el-option>
                                                                            <el-option
                                                                                v-for="(item, index) in form[formindex].special_processing_list"
                                                                                :key="index"
                                                                                :label="item.label"
                                                                                :value="item.value">
                                                                            </el-option>
                                                                            <div class="text-initial">
                                                                                <label
                                                                                    v-if="errors[formindex+'.special_processing_list']"
                                                                                    class="text-danger text-left">
                                                                                    {{
                                                                                        errors[formindex + '.special_processing_list'][0]
                                                                                    }}
                                                                                </label>
                                                                            </div>
                                                                        </el-select>
                                                                    </el-form-item>
                                                                </el-col>

                                                                <el-col :md="10">
                                                                    <el-form-item class="item-center item-fome-base" label="項目権限"
                                                                                  prop="special_processing">
                                                                        <el-select
                                                                            :disabled="!checkEditItem(form_item) || form[formindex].special_processing > 0"
                                                                            class="w--10 bg-gray-item"
                                                                            v-model="form[formindex].item_role"
                                                                            placeholder="なし">
                                                                            <el-option
                                                                                v-for="(item, index) in form[formindex].item_role_list"
                                                                                :key="index"
                                                                                :label="item.label"
                                                                                :value="item.value">
                                                                            </el-option>
                                                                        </el-select>
                                                                    </el-form-item>
                                                                </el-col>
                                                            </el-form-item>
                                                        </div>
                                                        <div v-if="form_base_selected.special_item != 0 && !constants.SPECIAL_PROCESSING_EMPTY_COPY_ITEM.includes(form[formindex].special_processing) && form_item.input_method != 9" class="transition-box checkbox-item">
                                                            <el-form-item prop="parent" label-width="0px">
                                                                <el-col :md="20" class="d-flex justify-content-end">
                                                                    <el-checkbox v-model="form[formindex].show_to_hide" @change="showHide(form_item,1)"  :disabled="disableDisplayStart(form[formindex])"></el-checkbox>
                                                                    <label class="item-fome-base" style="color: #606266;">
                                                                        選択式設問とする（表示→非表示）
                                                                    </label>
                                                                </el-col>
                                                            </el-form-item>
                                                            <el-form-item prop="parent" label-width="0px">
                                                                <el-col :md="20" class="d-flex justify-content-end">
                                                                    <el-checkbox v-model="form[formindex].hide_to_show" @change="showHide(form_item,2)"  :disabled="disableDisplayStart(form[formindex])"></el-checkbox>
                                                                    <label class="item-fome-base" style="color: #606266;">
                                                                        選択式設問とする（非表示→表示）
                                                                    </label>
                                                                </el-col>
                                                            </el-form-item>
                                                        </div>
                                                        <div v-if="!constants.SPECIAL_PROCESSING_EMPTY_COPY_ITEM.includes(form[formindex].special_processing)" class="transition-box checkbox-item">
                                                            <el-form-item prop="parent" label-width="0px">
                                                                <el-col :md="10">
                                                                    <el-form-item class="item-fome-base" label="回答時項目グループ複製許可"
                                                                                  prop="copy_item">
                                                                        <el-checkbox  :disabled="!checkEditItem(form_item) || [1].includes(form_base_selected.special_item)||form[formindex].input_method == 8" v-model="form[formindex].copy_item"></el-checkbox>
                                                                    </el-form-item>
                                                                </el-col>
                                                            </el-form-item>
                                                        </div>
                                                        <div class="transition-box"
                                                             v-if="ShowItemForm(form_item.check_method,constants.CHECK_METHOD[7].value)"
                                                        >
                                                            <div class="row m-0">
                                                                <div class="el-col el-col-24 el-col-md-10">
                                                                    <label class="el-form-item__label form-item-ran"
                                                                           style="">桁数</label>
                                                                    <div class="d-flex justify-content-between custom-ran">
                                                                        <div>
                                                                            <el-input :disabled="!checkEditItem(form_item)"
                                                                                      class="bg-gray-item" type="number" min="1" onkeypress="return this.value.length < 3;" oninput="if(this.value.length>=3) { this.value = this.value.slice(0,3); }"
                                                                                      v-model="form[formindex].range_from_digit"></el-input>
                                                                        </div>
                                                                        <div class="d-flex align-items-center" style="color: black">
                                                                            ～
                                                                        </div>
                                                                        <div>
                                                                            <el-input :disabled="!checkEditItem(form_item)" class="bg-gray-item" type="number" min="1" onkeypress="return this.value.length < 3;" oninput="if(this.value.length>=3) { this.value = this.value.slice(0,3); }"
                                                                                      v-model="form[formindex].range_to_digit"></el-input>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row custom-ran mt-2">
                                                                        <div v-if="errors[formindex+'.range_from_digit']" class="text-danger m-auto">
                                                                            {{ errors[formindex + '.range_from_digit'][0] }}
                                                                        </div>
                                                                        <div v-else-if="errors[formindex+'.range_to_digit']" class="text-danger m-auto">
                                                                            {{ errors[formindex + '.range_to_digit'][0] }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="transition-box"
                                                             v-if="ShowItemForm([input_method[2].value,input_method[3].value,],form_item.input_method)">
                                                            <el-form-item prop="parent" label-width="0px">
                                                                <el-col :md="10">
                                                                    <el-form-item label="選択項目" prop="selections">
<!--                                                                        <el-input :disabled="!checkEditItem(form_item)"  maxlength="1000"  class="bg-gray-item" v-model="form[formindex].selections"></el-input>-->
                                                                        <div class="bg-gray-item el-input">
                                                                            <input :class="disableForm ? 'not-allowed-form-item' : ''" type="text" autocomplete="off"  maxlength="1000" class="el-input__inner" :disabled="!checkEditItem(form_item)" v-model.lazy="form[formindex].selections" @change="addAnswerShow(form_item)">
                                                                        </div>
                                                                        <div class="text-initial">
                                                                            <label v-if="errors[formindex+'.selections']"
                                                                                   class="text-danger text-left">
                                                                                {{ errors[formindex + '.selections'][0] }}
                                                                            </label>
                                                                        </div>
                                                                    </el-form-item>
                                                                </el-col>
                                                            </el-form-item>
                                                        </div>
                                                        <div
                                                            v-if="ShowItemForm(form_item.input_method,input_method[5].value,true)"
                                                            class="transition-box">
                                                            <el-form-item class="item-fome-base" prop="parent" label-width="0px">
                                                                <el-form-item label="表示内容" prop="display_content">
<!--                                                                    <el-input :disabled="!checkEditItem(form_item)"  type="textarea" class="bg-gray-item"-->
<!--                                                                              v-model="form[formindex].display_content"-->
<!--                                                                              rows="5"></el-input>-->
                                                                    <div class="bg-gray-item el-textarea">
                                                                        <textarea :class="disableForm ? 'not-allowed-form-item' : ''" :disabled="!checkEditItem(form_item)" v-model.lazy="form[formindex].display_content" autocomplete="off" rows="5" class="el-textarea__inner" style="min-height: 33px;"></textarea>
                                                                    </div>
                                                                    <div class="text-initial">
                                                                        <label v-if="errors[formindex+'.display_content']"
                                                                               class="text-danger text-left">
                                                                            {{ errors[formindex + '.display_content'][0] }}
                                                                        </label>
                                                                    </div>
                                                                </el-form-item>

                                                            </el-form-item>
                                                        </div>
                                                        <div class="transition-box"
                                                             v-if="ShowItemForm(form_item.check_method,constants.CHECK_METHOD[8].value)">
                                                            <div class="row m-0">
                                                                <div class="el-col el-col-24 el-col-md-10">
                                                                    <label class="el-form-item__label form-item-ran"
                                                                           style="">容量</label>
                                                                    <div class="d-flex justify-content-between custom-ran">
                                                                        <div>
                                                                            <el-input :disabled="!checkEditItem(form_item)"
                                                                                      onkeydown="javascript: return event.keyCode !== 69 ? true : !isNaN(Number(event.key))"
                                                                                      class="bg-gray-item" type="number" min="1" onkeypress="return this.value.length < 3;" oninput="if(this.value.length>=3) { this.value = this.value.slice(0,3); }"
                                                                                      v-model="form[formindex].range_from_size"></el-input>
                                                                        </div>
                                                                        <div class="d-flex align-items-center" style="color: black">
                                                                            ～
                                                                        </div>
                                                                        <div>
                                                                            <el-input :disabled="!checkEditItem(form_item)"  class="bg-gray-item" type="number" min="1" onkeypress="return this.value.length < 3;" oninput="if(this.value.length>=3) { this.value = this.value.slice(0,3); }"
                                                                                      v-model="form[formindex].range_to_size"></el-input>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row custom-ran mt-2">
                                                                        <div v-if="errors[formindex+'.range_from_size']" class="text-danger m-auto">
                                                                            {{ errors[formindex + '.range_from_size'][0] }}
                                                                        </div>
                                                                        <div v-else-if="errors[formindex+'.range_to_size']" class="text-danger m-auto">
                                                                            {{ errors[formindex + '.range_to_size'][0] }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            v-if="ShowItemForm(form_item.check_method,constants.CHECK_METHOD[9].value)"
                                                            class="transition-box">
                                                            <el-form-item prop="parent" label-width="0px">
                                                                <el-col :md="10">
                                                                    <el-form-item class="item-fome-base" label="許可拡張"
                                                                                  prop="acceptable_extensions">
                                                                        <el-input  :disabled="!checkEditItem(form_item)"  class="bg-gray-item" maxlength="100"
                                                                                   v-model="form[formindex].acceptable_extensions"></el-input>
                                                                        <div class="text-initial">
                                                                            <label v-if="errors[formindex+'.acceptable_extensions']"
                                                                                   class="text-danger text-left">
                                                                                {{
                                                                                    errors[formindex + '.acceptable_extensions'][0]
                                                                                }}
                                                                            </label>
                                                                        </div>
                                                                    </el-form-item>
                                                                </el-col>
                                                            </el-form-item>
                                                        </div>
                                                        <div class="transition-box" v-if="form_base_selected && form_base_selected.parent_form_id && form_base_selected.copy_flag && ![1,2].includes(form[formindex].special_processing)">
                                                            <el-form-item prop="parent" label-width="0px">
                                                                <el-col :md="10">
                                                                    <el-form-item class="item-fome-base" label="コピー元ID"
                                                                                  prop="check_method">
                                                                        <el-select  :disabled="!checkEditItem(form_item)" class="w--10 bg-gray-item" v-model="form[formindex].copy_id" placeholder="入力チェックを入力">
                                                                            <el-option label="なし" value=" "></el-option>
                                                                            <el-option
                                                                                v-for="(item) in form[formindex].item_id_parent"
                                                                                :key="item.item_id"
                                                                                :label="convertCopyForm(item)"
                                                                                :value="String(item.item_id)">
                                                                            </el-option>
                                                                        </el-select>
                                                                        <div class="text-initial">
                                                                            <label v-if="errors[formindex+'.copy_id']"
                                                                                   class="text-danger text-left">
                                                                                {{ errors[formindex + '.copy_id'][0] }}
                                                                            </label>
                                                                        </div>
                                                                    </el-form-item>
                                                                </el-col>
                                                            </el-form-item>
                                                        </div>
                                                        <div class="transition-box" v-if="form_item.input_method == 8">
                                                            <el-form-item class="item-fome-base" label="添付ファイル" prop="check_method">
                                                                <el-col :md="10">
                                                                    <div class="row">
                                                                        <div class="col-md-9">
                                                                            <div class="">
                                                                                <div class="row">
                                                                                    <div class="col-md-4 text-justify">
                                                                                        <input  :disabled="!checkEditItem(form_item)"   :class="disableForm ? 'file-select' : ''" style="display: none" :id="'file-upload'+formindex" type="file" @change="uploadFile($event,form[formindex])">
                                                                                        <label :class="disableForm ? 'label-select' : ''"  :for="'file-upload'+formindex" class="file-drag file-upload-btn btn btn-primary">
                                                                                            選択
                                                                                        </label>
                                                                                    </div>
                                                                                    <div v-if="form_item.item_file_path" style="color:deepskyblue;" class="col-md-8">
                                                                                        <a :href="form_item.url_download_file">{{ showNAmeFile(form_item) }}</a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="text-initial">
                                                                        <label v-if="errors[formindex+'.item_file_path']"
                                                                               class="text-danger text-left">
                                                                            {{ errors[formindex + '.item_file_path'][0] }}
                                                                        </label>
                                                                    </div>
                                                                </el-col>
                                                            </el-form-item>
                                                        </div>
                                                        <div class="transition-box" v-if="form_item.input_method == 9">
                                                            <el-form-item prop="parent" label-width="0px">
                                                                <el-col :md="6">
                                                                    <el-form-item class="item-fome-base" label="項目選択">
                                                                        <div class="d-flex">
                                                                            <el-select class="w--10 bg-gray-item mr-1"
                                                                                        v-model="form_item.calc_item"
                                                                                       placeholder="追加ボタン">
                                                                                <el-option label="なし" value=" "></el-option>
                                                                                <el-option  v-for="(item, index) in listItemCalc" v-if="form_item.item_id != item.item_id"
                                                                                    :key="index"
                                                                                    :label="item.label_name"
                                                                                    :value="item.item_id">
                                                                                </el-option>
                                                                            </el-select>
                                                                            <el-button :disabled="form_item.calc_item == ''" @click="addCalcItem(form_item,form_item.calc_item,1)" style="width: 56px" class="btn-add-item btn-all-p w---100 btn-primary-and-text" v-bind:class="{'bg-disable-button': form_item.calc_item == ''}">+</el-button>
                                                                        </div>
                                                                    </el-form-item>
                                                                </el-col>

                                                                <el-col :md="6">
                                                                    <el-form-item class="item-center item-fome-base" label="演算子">
                                                                        <div class="d-flex">
                                                                            <el-select
                                                                                class="w--10 bg-gray-item mr-1"
                                                                                v-model="form_item.calc_method"
                                                                                placeholder="追加ボタン">
                                                                                <el-option
                                                                                    v-for="(item, index) in constants.CALC_METHOD"
                                                                                    :key="index"
                                                                                    :label="constants.CALC_MAP[item]"
                                                                                    :value="item">
                                                                                </el-option>
                                                                            </el-select>
                                                                            <el-button :disabled="form_item.calc_method == ''" @click="addCalcItem(form_item,form_item.calc_method,2)" style="width: 56px" class="btn-add-item btn-all-p w---100 btn-primary-and-text" v-bind:class="{'bg-disable-button': form_item.calc_method == ''}">+</el-button>
                                                                        </div>
                                                                    </el-form-item>
                                                                </el-col>

                                                                <el-col :md="6">
                                                                    <el-form-item class="item-center item-fome-base" label="数字">
                                                                        <div class="d-flex">
                                                                            <el-input class="bg-gray-item mr-1" v-model="form_item.number_calc" placeholder="追加ボタン"  @input="validCalcNumber($event,form[formindex])"></el-input>
                                                                            <el-button :disabled="form_item.number_calc == ''" @click="addCalcItem(form_item,form_item.number_calc,3)" style="width: 56px" class="btn-add-item btn-all-p w---100 btn-primary-and-text" v-bind:class="{'bg-disable-button': form_item.number_calc == ''}">+</el-button>
                                                                        </div>
                                                                    </el-form-item>
                                                                </el-col>
                                                                <el-col :md="10">
                                                                    <el-form-item class="item-fome-base" label="演算式確認:">
                                                                        <div class="d-flex align-items-end">
                                                                            <div class="d-flex flex-column w-100">
                                                                                <div v-for="(item,index) in form_item.calculation" :key="index+200" class="bg-gray-item preview-table text-break overflow-hidden">
                                                                                  <template v-if="item.type == 2">
                                                                                    {{ constants.CALC_MAP[item.label] }}
                                                                                  </template>
                                                                                  <template v-else>
                                                                                    {{ item.label }}
                                                                                  </template>
                                                                                </div>
                                                                            </div>
                                                                            <button v-if="form_item.calculation.length > 0" @click.prevent="removeCalcItem(form_item)" style="width: 56px" class="btn-all-p ml-1 btn btn-danger text-white btn-detele-item btn-delete-calculation">x</button>
                                                                        </div>
                                                                        <p style="color:black">{{ arrayAsString(form_item.calculation) }}</p>
                                                                        <div class="text-initial">
                                                                            <label v-if="errors[formindex+'.calculation']"
                                                                                   class="text-danger text-left">
                                                                                {{ errors[formindex + '.calculation'][0] }}
                                                                            </label>
                                                                        </div>
                                                                    </el-form-item>
                                                                </el-col>
                                                            </el-form-item>
                                                        </div>
                                                        <div class="transition-box" v-if="form_item.input_method == 9">
                                                            <el-form-item prop="parent" label-width="0px">
                                                                <el-col :md="10">
                                                                    <el-form-item class="item-fome-base" label="小数点の桁数:">
                                                                        <div class="d-flex align-items-end">
                                                                            <el-input  :disabled="!checkEditItem(form_item)"  class="bg-gray-item"
                                                                                v-model="form[formindex].number_decimal" @input="validNumber($event,form[formindex])"></el-input>
                                                                        </div>
                                                                    </el-form-item>
                                                                </el-col>
                                                            </el-form-item>
                                                        </div>
                                                        <div class="transition-box" v-if="form_item.input_method == 9">
                                                            <el-form-item prop="parent" label-width="0px">
                                                                <el-col :md="10">
                                                                    <el-form-item class="item-fome-base" label="丸めルール:">
                                                                        <div class="d-flex align-items-end">
                                                                            <el-select  :disabled="!checkEditItem(form_item)" class="w--10 bg-gray-item" v-model="form[formindex].rounding_type" placeholder="">
                                                                                <el-option label="なし" value=" "></el-option>
                                                                                <el-option
                                                                                    v-for="(item,key) in constants.ROUNDING_CALCULATION"
                                                                                    :key="key"
                                                                                    :label="item.label"
                                                                                    :value="item.value">
                                                                                </el-option>
                                                                            </el-select>
                                                                        </div>
                                                                    </el-form-item>
                                                                </el-col>
                                                            </el-form-item>
                                                        </div>
                                                    </div>
                                                </el-collapse-transition>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-5">
                                        <div class="el-col-md-8 offset-md-4 justify-content-between el-col-24">
                                            <div class="row m-0">
                                                <div class="el-col-12 ">
                                                    <div class="float-left w-96 m-auto">
                                                        <el-button :disabled="disableForm" class="btn-add-item btn-all-p w---100 btn-primary-and-text" @click="addNewItem">項目追加</el-button>
                                                    </div>
                                                </div>
                                                <div class="el-col-12">
                                                    <div class="w-96 m-auto float-right">
                                                        <el-button :disabled="disableForm" @click="chooseFiles" class="btn-add-item btn-all-p w---100 btn-primary-and-text" v-bind:class="{'bg-disable-button': disableForm}">
                                                            一括アップファイル選択
                                                        </el-button>
                                                        <input id="fileUploadExcel" v-on:change="uploadFileExcel" type="file" hidden>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="row justify-content-between mb-5">
                                        <div class="el-col-md-8 offset-md-4 el-col-24">
                                            <el-form-item label-width="250px" label="コピー元項目グループ名">
                                                <div class="d-flex">
                                                    <el-select class="w--10 bg-gray-item pr-3" v-model="coppy_item_group_id" placeholder="選択してください">
                                                        <el-option v-for="(item, index) in form_base_selected.item_group_names" :key="index" :label="item" :value="index"></el-option>
                                                    </el-select>
                                                    <el-button :disabled="disableForm" @click="copyitemByGroupName" style="width: 56px" class="btn-add-item btn-all-p w---100 btn-primary-and-text">コピー</el-button>
                                                </div>
                                            </el-form-item>
                                        </div>
                                    </div>
                                    <div  class="row justify-content-between mb-5">
                                        <div class="el-col-md-8 offset-md-4 el-col-24">
                                            <el-form-item label-width="250px" label="コピー元項目グループ名（中）">
                                                <div class="d-flex">
                                                    <el-select class="w--10 bg-gray-item pr-3" v-model="coppy_item_group_middle_id" placeholder="選択してください">
                                                        <el-option v-for="(item, index) in form_base_selected.item_group_name_middle" :key="index" :label="item" :value="index"></el-option>
                                                    </el-select>
                                                    <el-button :disabled="disableForm" @click="copyitemByGroupNameMiddle" style="width: 56px" class="btn-add-item btn-all-p w---100 btn-primary-and-text">コピー</el-button>
                                                </div>
                                            </el-form-item>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="el-col-md-8 offset-md-4 justify-content-between el-col-24">
                                            <div class="row m-0 flex-nowrap" >
                                                <div class="el-col-6 mr-2">
                                                    <div class="float-left w-90 m-auto">
                                                        <el-button
                                                            :disabled="disableForm"
                                                            class="form-item-view btn-all-p w---100 btn-primary-and-text" @click="isSubmit=true, submitSendMail = false">入力完了</el-button>
                                                    </div>
                                                </div>
                                                <div class="el-col-6 ">
                                                    <div class="float-left w-90 m-auto">
                                                        <el-button
                                                            :disabled="disableForm"
                                                            class="form-item-view btn-all-p w---100 btn-primary-and-text" @click="isSubmit=true, submitSendMail = true">入力完了（差戻し）</el-button>
                                                    </div>
                                                </div>
                                                <div class="el-col-6">
                                                    <div class="w-90 m-auto">
                                                        <el-button :disabled="!isDrap || disableForm" v-bind:class="{'bg-disable-button': !isDrap}" @click="submitForm(constants.URL_FORM_ITEM.form_item_tmp,constants.FORM_ITEM_STATUS.save_drap)" class="form-item-view btn-all-p w---100 btn-primary-and-text" >途中保存</el-button>
                                                    </div>
                                                </div>
                                                <div  class="el-col-6">
                                                    <div class="float-right w-90 m-auto">
                                                        <el-button
                                                            @click="submitForm(constants.URL_FORM_ITEM.form_item_tmp,constants.FORM_ITEM_STATUS.preview)" class="form-item-view btn-all-p w---100 btn-primary-and-text" >プレビュー</el-button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </tabPane>
                        </myTab>
                    </template>
                </el-form>
            </div>
            <Popup id="PopUPValidate" v-bind:content="$t('FORM.VALIDATION.ISSET')" :title_button="close_popup"></Popup>
            <div>
                <Dialog v-on:closeDialog="backFormBase" v-on:submit="changeFormBase(true)" v-bind:dialog="isShowPopup"
                        :closeOnClickModal="false" width="28%" btnOk="はい" :showCancelButton="true" btnCancel="いいえ">
                    <template slot="header">
                        <div class="textwidget">
                            <h3 class="text-center">確認</h3>
                        </div>
                    </template>
                    <template slot="content">
                        <div class="textwidget text-center">
                            <p>{{ $t('FORM.SETTING.CHANGE_FORM_BASE') }}</p>
                        </div>
                    </template>
                </Dialog>
            </div>
            <div class="submit-form-item">
                <Dialog v-on:closeDialog="isSubmit=false , submitSendMail = false" v-on:submit="disableSubmit = true;submitForm(constants.URL_FORM_ITEM.form_item)" v-bind:dialog="isSubmit"
                        :closeOnClickModal="false" width="28%" btnOk="はい" :showCancelButton="true" btnCancel="いいえ" :disabled="disableSubmit">
                    <template slot="header">
                        <div class="textwidget mb-5">
                            <h3 class="text-center">確認</h3>
                        </div>
                    </template>
                    <template slot="content">
                        <div class="textwidget">
                            <p>{{ $t('FORM.SETTING.FORM_ITEM_CONFIRM_SUBMIT',{form_name: form_name}) }}</p>
                        </div>
                    </template>
                </Dialog>
            </div>
            <div class="submit-form-item">
                <Dialog v-on:closeDialog="isDeleteAItem=false" v-on:submit="deleteItem" v-bind:dialog="isDeleteAItem"
                        :closeOnClickModal="false" width="28%" v-bind:btnOk="$t('DIALOG.BTN_OK_FORM_ITEM_DELETE')" :showCancelButton="true" v-bind:btnCancel="$t('DIALOG.BTN_CANCLE_FORM_ITEM_DELETE')">
                    <template slot="header">
                        <div class="textwidget mb-5">
                            <h3 class="font-weight-bold">{{ $t('DIALOG.TITLE_FORM_ITEM_DELETE') }}</h3>
                        </div>
                    </template>
                    <template slot="content">
                        <div class="textwidget">
                            {{ $t('DIALOG.BODY_FORM_ITEM_DELETE') }}
                        </div>
                    </template>
                </Dialog>
            </div>
            <div class="submit-form-item refresh_page">
                <Dialog v-on:closeDialog="confirm_reload=false" v-on:submit="enterByKeyCode" v-bind:dialog="confirm_reload"
                        :closeOnClickModal="false" width="28%" v-bind:btnOk="$t('DIALOG.BTN_OK_REFRESH_PAGE')" :showCancelButton="true" v-bind:btnCancel="$t('DIALOG.BTN_CANCLE_REFRESH_PAGE')">
                    <template slot="header">
                        <div class="textwidget mb-5">
                            <h3 class="font-weight-bold">{{ $t('DIALOG.TILE_REFRESH_PAGE') }}</h3>
                        </div>
                    </template>
                    <template slot="content">
                        <div class="textwidget">
                            {{ $t('DIALOG.BODY_REFRESH_PAGE') }}
                        </div>
                    </template>
                </Dialog>
            </div>
            <div class="submit-form-item refresh_page">
                <Dialog v-on:submit="isSaveDrap=false" v-bind:dialog="isSaveDrap"
                        :closeOnClickModal="false" width="28%" btnOk="閉じる" :showCancelButton="false">
                    <template slot="header">
                        <div class="textwidget">
                            {{ $t('DIALOG.BODY_SAVE_DRAP') }}
                        </div>
                    </template>
                </Dialog>
            </div>
            <my-dialog v-on:closeDialog="not_permisson_popup=false" v-on:submit="not_permisson_popup=false;$router.push({name: 'permission-denied'});" v-bind:dialog="not_permisson_popup"
                       :closeOnClickModal="false" width="28%" v-bind:btnOk="$t('DIALOG.BTN_OKIE_PERMISSON_DENIED')" :showCancelButton="false">
                <template slot="header">
                    <div class="textwidget text-center">
                        {{ $t('DIALOG.TITLE_PERMISSON_DENIED') }}
                    </div>
                </template>
            </my-dialog>

            <my-dialog v-on:closeDialog="closeDialogChangeTab" v-on:submit="addTab" v-bind:dialog="popup_change_tab"
                       :closeOnClickModal="false" width="28%" btnCancel="いいえ" btnOk="はい">
                <template slot="header">
                    <div class="textwidget text-center mb-5">
                        <h3 class="font-weight-bold">確認</h3>
                    </div>
                </template>
                <template slot="content">
                    <div class="textwidget text-center">
                        フォームを変更する場合作成したフォームデータが全て保存します。 本当に保存しますか？
                    </div>
                </template>
            </my-dialog>

            <my-dialog v-on:closeDialog="popup_coppy= false" v-on:submit="appendCoppy" v-bind:dialog="popup_coppy"
                       :closeOnClickModal="false" width="28%" btnCancel="いいえ" btnOk="はい">
                <template slot="header">
                    <div class="textwidget text-center mb-5">
                        <h3 class="font-weight-bold">確認</h3>
                    </div>
                </template>
                <template slot="content">
                    <div v-if="!coppy_flag_check" class="textwidget text-center">
                        コピー元項目グループ名をコピーしますか？
                    </div>
                    <div v-else class="textwidget text-center">
                        コピー元項目グループ名（中）をコピーしますか？
                    </div>
                </template>
            </my-dialog>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .btn-delete-related{
        padding: 0 !important;
        width: 100%;
        border: none;
        line-height: unset;

    }
    .btn-copy-a-item {
        background-color: #00aff0;
        padding: 5px 30px;
    }
    .card-custom {
        border-radius: 15px;
    }

    .card-custom > .card-header {
        padding: 0;
        margin: 0px 21px;
    }

    .card-sub-title {
        border-top: 1px solid #EBEDF3;
        border-bottom: 1px solid #EBEDF3;
        padding: 1rem 0.25rem;

    }

    .transition-box {
        margin-bottom: 10px;
        border-radius: 4px;
        text-align: center;
        color: #fff;
        box-sizing: border-box;
    }

    .text-initial {
        text-align: initial;
    }

    .w--10 {
        width: 100%;
    }

    .text-size-27 {
        font-size: 27px;
    }
    .btn-detele-item{
        padding: 5px 36px;
        :hover{
            background-color: #EE2D41 !important;
        }
    }
    .btn-all-p{
        padding: 12px 5px
    }
    .w-90{
        width: 90%;
    }
    .w---100{
        width: 100%;
    }
    .btn-primary-and-text{
        background-color: #00aff0;
        color: white;
        border-radius: 4px;
    }
    .bg-disable-button{
        background-color: rgba(9,30,66,.48);
    }
    .checkbox-item{
        text-align: left;
    }
    .w-96 {
        width: 96%;
    }
    .preview-table {
        border: 1px solid #DCDFE6;
        color: #606266;
        font-size: inherit;
        height: 40px;
        line-height: 40px;
        outline: 0;
        padding: 0 15px;
        width: 100%;
    }
    .btn-delete-calculation {
        width: 56px;
        height: 40px;
        align-items: center;
        display: flex;
        justify-content: center;
    }
</style>

<script>
    import * as constants from "@/core/config/constant";
    import Popup from "@/view/components/Popup";
    import Dialog from "@/view/components/Dialog";
    import myTab from "@/view/components/tabs/src/tabs";
    import tabPane from "@/view/components/tabs/src/tab-pane";
    import {LIST_REDIRECT} from "@/core/services/store/confirmRedirectPage.module";
    import { mapActions } from "vuex";
    import {
        GET_ALL_LIST_FORM_BASE,
        FORM_ITEM_REGISTER,
        FORM_ITEM_SAVE_CHANGE_TAB,
        FORM_ITEM_DELETE_BY_ID,
        COPY_GROUP,
        GET_FORM_ITEM_BY_ID,
        FORM_ITEM_UPLOAD_FILE
    } from "@/core/services/store/formStore";
    import {
        BUILD_URL_DOWNLOAD_FILE,
    } from "@/core/services/store/file.module";
    import {
        CHECK_METHOD,
        CHECK_METHOD_CUSTOM,
        CHECK_METHOD_DEFAULT, CHECK_METHOD_DEFAULT_ALL,
        CHECK_METHOD_INPUT_CHECKBOK_,
        CHECK_METHOD_INPUT_FILE, CHECK_METHOD_LENGTH,
        CHECK_METHOD_UNIQUE, COPY_ITEM_STATUS,
        DISPLAY_ORDER_DEFUALT,
        DISPLAY_ORDER_DÈUALT,
        FORM_ITEM_DEFAULT,
        FORM_ITEM_STATUS,
        LIST_CHECK_RESET_FORM, PASSWORD_LENGTH, QUANTITY_FORM_ITEM,
        SPECIAL_PROCESSING,
        SPECIAL_PROCESSING_CUSTOM,
        STATUS_CODE,
        FIELDS, CHECK_METHOD_INPUT_TEXT
    } from "../../../../core/config/constant";

    export default {
        components: {
            Popup,
            Dialog,
            myTab,
            tabPane
        },
        data() {
            return {
                submitSendMail:false,
                disableForm :false,
                activeName: 1,
                totalItemForm: 0,
                popup_change_tab: false,
                popup_coppy: false,
                coppy_flag_check: false,
                itemChangeTab: {},
                formCache:[],
                isDrap:false,
                form_append:{},
                flagChangeTab:false,
                value1:'',
                form: [
                    {
                        item_id:'',
                        form_id: '',
                        item_group_name: '',
                        item_group_name_middle:'',
                        label_name: '',
                        check_method: [],
                        range_from_digit: '',
                        range_from_size: '',
                        range_to_digit: '',
                        range_to_size: '',
                        input_method: '',
                        display_order: constants.DISPLAY_ORDER_DEFUALT,
                        special_processing: '',
                        selections: '',
                        display_content: '',
                        acceptable_extensions: '',
                        toggle: true,
                        special_processing_list: constants.SPECIAL_PROCESSING_CUSTOM,
                        check_method_list: constants.CHECK_METHOD,
                        item_role_list: constants.ITEM_ROLE,
                        item_role: constants.ITEM_ROLE_DEFAULT,
                        copy_id: '',
                        copy_item: false,
                        item_file_path:'',
                        status:0,
                        calc_item: '',
                        calc_method: '',
                        number_calc: '',
                        calculation: [],
                        calc_flg: null,
                        related_items: [],
                        answer_action: [],
                        show_to_hide: false,
                        hide_to_show: false,
                        list_answer_show: [],
                        number_decimal: null,
                        rounding_type: null,
                        id_parent_related: null
                    },

                ],
                form_base_select: [],
                form_base_select_drap: [],
                form_list: [],
                form_group_name: [],
                item_group_name_middle_list: [],
                errors: [],
                form_id: null,
                constants: constants,
                loading: false,
                isShowPopup: false,
                old_form_id: '',
                isSubmit:false,
                form_name:'',
                isDeleteAItem:false,
                itemKey:'',
                confirm_reload:false,
                isSaveDrap:false,
                checkDrap:false,
                current_form_url:'',
                close_popup: this.$t("close_popup_error"),
                interval:null,
                item_id_parent: [],
                form_base_selected:[],
                form_base_Parent:[],
                disableSubmit: false,
                coppy_item_group_id:'',
                coppy_item_group_middle_id:'',
                not_permisson_popup: false,
                listItemCalc: [],
                listQuestionShow: [],
                input_method: [],
                checkSelected: [],
                arrayRelatedSelected: [],
                arraySelected: [],
            }
        }, computed: {
            queryParam() {
                return this.$route.query;
            }
        }, mounted() {
            window.addEventListener("keydown", function (event) {
                this.checkReload(event)
            }.bind(this));
            window.addEventListener("keyup", function (event) {
                this.checkReload(event)
            }.bind(this));

        },
        watch: {
            'form'(newValue) {
                this.loading = true
                newValue.map(async (value) => {
                    await this.changeInputMethod(value)
                    await this.changeCheckMethod(value)
                    await this.changeRelatedItem()
                    this.loading = false
                })
            },
            'form_id'(newVal, oldVal) {
                this.old_form_id = oldVal;
            },
        },
        async created() {
            this.input_method = JSON.parse(JSON.stringify(constants.INPUT_METHOD));
            if (this.hasPermissonCheck('form_flg')){
                await this.getListFormBases()
                this.updateFormLabelFormBase()
                if (this.queryParam.tab) {
                    this.activeName = parseInt(this.queryParam.tab)
                }
                if(this.queryParam.form_id){
                    this.form_id = parseInt(this.queryParam.form_id)
                    await this.changeFormBase();
                }
            }else {
                this.not_permisson_popup = true
            }
            this.addNewItemInit();
            // window.addEventListener('scroll',await this.handleScroll);
        },
        destroyed () {
            // window.removeEventListener('scroll', this.handleScroll);
        },
        methods: {
            ...mapActions([LIST_REDIRECT]),
            async handleScroll (event) {
                if (document.body.scrollHeight - window.pageYOffset <= 800){
                    await this.pushDataScroll();
                }

            },

            async pushDataScroll(){
                if (this.formCache.length > 0){
                    let count = 0;
                    while (count < 5){
                        this.form.push(this.formCache.shift());
                        count++;
                        if (this.formCache.length === 0){
                            break;
                        }
                    }
                }
            },
            async getListFormBases() {
                this.loading = true
                await this.$store.dispatch(GET_ALL_LIST_FORM_BASE).then(data => {
                    this.loading = false
                    if (data && data.data) {
                        if (data.code === constants.STATUS_CODE.SUCCESS) {
                            this.form_base_select_drap = this.form_base_select = data.data.map(formBase => {
                                return {
                                    label: formBase.form_name,
                                    value: formBase.form_id,
                                    saveDrap:formBase.saveDrap
                                }
                            })
                            this.form_list = data.data.map(formBase => {
                                let arrList = [];
                                arrList['id'] = formBase.form_id;
                                arrList['form_group'] = formBase.item_group_names;
                                arrList['item_group_name_middle_list'] = formBase.item_group_name_middle;
                                arrList['form_name']=formBase.form_name
                                arrList['form_url']=formBase.form_url
                                return arrList;
                            })
                        } else {
                            this.notifyError(data.message)
                        }
                    } else {
                        this.notifyError(this.$t("ERROR_SERVER"))
                    }
                }).catch(e => {
                    this.loading = false
                    this.notifyError(e)
                })
                this.loading = false

            },
            addNewItem() {
                if (this.form.length == constants.QUANTITY_FORM_ITEM || this.form.length > constants.QUANTITY_FORM_ITEM) {
                   this.popup_change_tab = true

                }else {
                   this.form.push(this.dataDefault());
                }
            },
            addNewItemInit() {
                if (this.form.length == 0 ) {
                    this.form.push(this.dataDefaultInitEmail(),this.dataDefaultInitPW());
                }
            },
            showItem(form) {
                form.toggle = !form.toggle
            },
            getFormItemById(id,isChangeForm = false) {
                this.loading = true
                if (isChangeForm) {
                    this.activeName = 1
                }
                let tab = this.activeName
                this.$router.replace({ name: 'form-item-add', query: { ...{ tab: tab,form_id: this.form_id  } }})
                this.$store.dispatch(GET_FORM_ITEM_BY_ID, {id,tab}).then(data => {
                    this.loading = false
                    if (data && data.data) {
                        if (data.code === constants.STATUS_CODE.SUCCESS) {
                            this.item_id_parent = data.data.formItemParent;
                            this.form_base_selected = data.data.formBase;
                            this.arrayRelatedSelected = data.data.listItemRelated;
                            this.arraySelected = data.data.arrSelect;
                            this.input_method = JSON.parse(JSON.stringify(constants.INPUT_METHOD));
                            if ([0].includes(this.form_base_selected.special_item)) {
                                this.input_method.splice(8, 1);
                            }
                            this.listItemCalc = data.data.listItemCalc.filter(item => {
                                return (
                                    (item.input_method == 1 &&
                                        item.item_check_relation.some(check => check.check_method == 3) &&
                                        item.display_start == 0)
                                    ||
                                    (item.input_method == 9 && item.display_start == 0)
                                );
                            })
                            this.listQuestionShow = data.data.listItemCalc.filter(item => {
                                return (item.label_name != null && item.display_start > 0);
                            });
                           if (data.data.formBase && data.data.formBase.disable){
                               this.disableForm = true
                           }else {
                               this.disableForm = false
                           }
                            this.form_base_Parent = data.data.formBAseParent;
                            this.isDrap = data.data.isDrap;

                            let totalCheck =  parseInt(data.data.total) / constants.QUANTITY_FORM_ITEM;
                            let totalCheckFloor = Math.floor(totalCheck);
                            if (totalCheck < 1) {
                                this.totalItemForm = 1
                            }else if (totalCheck == totalCheckFloor) {
                                this.totalItemForm = totalCheck
                            }else {
                                this.totalItemForm = totalCheckFloor + 1
                            }

                            if (data.data.formItem.length) {
                                this.mapData(data.data.formItem);
                                if (Object.keys(this.form_append).length) {
                                    this.form.push(this.form_append)
                                    this.form_append = {}
                                }
                            } else {
                                if (this.activeName ==1) {
                                    this.checkDrap =false
                                    this.form = [
                                        // this.dataDefault()
                                    ];
                                }else {
                                    if (Object.keys(this.form_append).length) {
                                        this.totalItemForm = this.totalItemForm + 1
                                        this.activeName = this.totalItemForm
                                        this.form=[this.form_append]
                                        this.form_append = {}
                                    }else {
                                        this.activeName = this.activeName -1
                                        this.getFormItemById(id)
                                    }

                                }
                            }
                            this.addNewItemInit()
                            this.changeRelatedItem();
                        } else {
                            this.notifyError(data.message)
                        }
                    } else {
                        this.notifyError(this.$t("ERROR_SERVER"))
                    }
                }).catch(e => {
                    this.loading = false
                    this.notifyError(e)
                })

            },
            changeInputMethod(data, flag = false,formindex = 0) {
                if (flag) {
                    this.listQuestionShow.map(element => {
                        if(data.related_items.includes(element.item_id)) {
                            element.selected = false
                        }
                    });
                    data.calculation = []
                    data.related_items = [[]]
                    data.calc_item = ''
                    data.calc_method = ''
                    data.number_calc = ''
                    data.number_decimal = null
                    data.rounding_type = null
                    this.mappingCopy(data)
                }
                switch (data.input_method) {
                    case 1: {
                        data.item_file_path = ''
                        data.special_processing_list = constants.SPECIAL_PROCESSING
                        data.check_method_list = this.newListCheckMethod(constants.CHECK_METHOD_INPUT_TEXT)
                        break;
                    }case 2: {
                        data.item_file_path = ''
                        data.special_processing_list = constants.SPECIAL_PROCESSING_CUSTOM
                        data.check_method_list = this.newListCheckMethod(constants.CHECK_METHOD_DEFAULT)
                        break;
                    }
                    case 3: {
                        data.item_file_path = ''
                        data.special_processing_list = constants.SPECIAL_PROCESSING_CUSTOM
                        data.check_method_list = this.newListCheckMethod(CHECK_METHOD_INPUT_CHECKBOK_)
                        // data.item_id_parent= {}
                        break;
                    }
                    case 4: {
                        data.item_file_path = ''
                        data.special_processing_list = constants.SPECIAL_PROCESSING_CUSTOM
                        data.check_method_list = this.newListCheckMethod(CHECK_METHOD_INPUT_CHECKBOK_)
                        // data.item_id_parent= {}
                        break;
                    }
                    case 5: {
                        data.item_file_path = ''
                        data.special_processing_list = constants.SPECIAL_PROCESSING_CUSTOM
                        data.check_method_list = this.newListCheckMethod(CHECK_METHOD_INPUT_FILE)
                        data.item_id_parent= {}
                        break;
                    }
                    case 6: {
                        data.item_file_path = ''
                        data.special_processing_list = constants.SPECIAL_PROCESSING_CUSTOM
                        data.check_method_list = []
                        data.item_id_parent= {}
                        break;
                    }
                    case 7: {
                        data.item_file_path = ''
                        data.special_processing_list = constants.SPECIAL_PROCESSING_CUSTOM
                        data.check_method_list = this.newListCheckMethod(CHECK_METHOD_INPUT_CHECKBOK_)
                        break;
                    }
                    case 8: {
                        data.special_processing_list = constants.SPECIAL_PROCESSING_CUSTOM
                        data.copy_item =  false,
                        data.check_method_list = []
                        break;
                    }
                    case 9: {
                        data.special_processing_list = constants.SPECIAL_PROCESSING_CUSTOM
                        data.check_method_list = []
                        data.show_to_hide = false
                        data.hide_to_show = false
                        break;
                    }
                    default: {
                        data.item_file_path = ''
                        data.special_processing_list = constants.SPECIAL_PROCESSING_CUSTOM
                        data.check_method_list = this.newListCheckMethod(constants.CHECK_METHOD_DEFAULT_ALL)
                    }
                }
                this.filterCalcItem(data)
                if (flag) {
                    data.check_method = []
                    let defaultErrors=['display_content','selections','item_file_path','acceptable_extensions','range_from_size','range_to_size']
                    let formItemDefault = constants.FORM_ITEM_DEFAULT
                    let vm = this;
                    Object.keys(data).forEach(function (key) {
                        if (defaultErrors.includes(key)){
                            vm.errors[formindex+'.'+key] =[]
                        }
                        if (formItemDefault.includes(key)) {
                            data[key] = ""
                        } else if (key == 'display_order') {
                            //data[key] = constants.DISPLAY_ORDER_DEFUALT
                        }
                    })
                    data.answer_action = [[]]
                }

            },
            ShowItemForm(currenObj, value, flag) {
                return flag ? currenObj == value : currenObj.includes(value)
            },
            changeCheckMethod(formItem) {
                this.filterCalcItem(formItem)
                formItem.check_method.map(function (values, keys) {
                    if (values==' '){
                        formItem.check_method=[]
                    }
                });
                let list = [];
                let count = 0;
                let listUnique = constants.CHECK_METHOD_UNIQUE  // [3, 4, 5, 6, 7, 11, 12]
                let listDefault = JSON.parse(JSON.stringify(constants.CHECK_METHOD_CUSTOM))//[1, 2, 8,9, 10]
                let value = listUnique.filter((value, key) => {
                    if (formItem.check_method.includes(value)) {
                        return value
                    }
                })
                if (value[0]) {
                    listDefault.push(value[0])
                    listDefault.sort((a, b) => {
                        return a - b;
                    })
                    formItem.check_method_list = formItem.check_method_list.filter(method => {
                        if (listDefault.includes(method.value)) {
                            return method
                        }
                    })
                } else {
                    this.changeInputMethod(formItem)
                }
            },
            newListCheckMethod(listObj) {
                return constants.CHECK_METHOD.filter((el) => {
                    return listObj.includes(el.value)
                })
            },
            removeElement(array, elem) {
                var index = array.indexOf(elem);
                if (index > -1) {
                    array.splice(index, 1);
                }
            },
            submitForm(url,status=false) {
                this.disableSubmit = true
                this.isSubmit=false
                this.loading = true
                this.$store.dispatch(FORM_ITEM_REGISTER, {data:this.form,url:url,status:status,isSendmail: this.submitSendMail }).then(data => {
                    this.loading = false
                    this.disableSubmit = false
                    if (data.code === constants.STATUS_CODE.SUCCESS) {
                        this.errors=[]
                        this.mapData(data.data.formItem);
                        if (status==FORM_ITEM_STATUS.save_drap){
                            this.activeName = 1
                            this.isSaveDrap = true
                            this.updateFormLabelFormBase()
                        }else if (status==FORM_ITEM_STATUS.preview){
                            this.listQuestionShow = []
                            this.activeName = 1
                            let route = this.$router.resolve({name: "form-preview", params: {form_base_url: this.current_form_url}});
                            window.open(route.href);
                        }else {
                            this.notifySuccess(this.$t('FORM.SETTING.SUCCESS', {form_name: this.form_name}))

                            setTimeout(()=>{
                                this.form=[]
                                this.$router.push({name: "form-base-list"})
                            }, 200);
                        }
                    } else if (data.code === constants.STATUS_CODE.BAD_REQUEST) {
                        this.errors = data.data
                        let firstErrors = Object.keys(this.errors )[0].split(".")
                        let indexError = firstErrors[0]
                        if ( this.$refs['scroolError'+indexError]){
                            this.$refs['scroolError'+indexError][0].scrollIntoView();
                        }
                        if (data.data.showPopup){
                            this.$bvModal.show('PopUPValidate')
                        }
                        return
                    }  else {
                        this.notifyError(data.message)
                    }
                }).catch(e => {
                    this.loading = false
                    this.disableSubmit = false
                    this.notifyError(e)
                })
            },
            changeFormBase(isChangeForm = false) {
                let curren_form_group_name = this.form_list.find((fil) => {
                    return fil.id == this.form_id
                });
                this.current_form_url = curren_form_group_name['form_url']
                this.form_group_name = curren_form_group_name['form_group'];
                this.item_group_name_middle_list = curren_form_group_name['item_group_name_middle_list'];
                this.form_name=curren_form_group_name['form_name']
                this.getFormItemById(this.form_id,isChangeForm)
                this.errors = []
                this.isShowPopup = false
            },
            backFormBase() {
                this.form_id = this.old_form_id
                this.isShowPopup = false
                this.old_form_id = ''
            },
            getFormBase() {
                let check = this.resetForm();
                if (check) {
                    this.isShowPopup = true
                } else {
                    this.errors = []
                    this.changeFormBase(true)
                }
            },
            resetForm() {
                let checkResetForm = 0
                let seft = this
                let newObject = this.form.map(function (values, keys) {
                    checkResetForm=seft.resetFormItem(values,checkResetForm)
                });
                return checkResetForm;
            },
            dataDefault(){
                const item_id = 'itemId_'+this.randomString(5)
                return {
                    item_id: item_id,
                    form_id: this.form_id,
                    item_group_name: '',
                    item_group_name_middle:'',
                    label_name: '',
                    check_method: [],
                    range_from_digit: '',
                    range_from_size: '',
                    range_to_digit: '',
                    range_to_size: '',
                    input_method: '',
                    display_order: constants.DISPLAY_ORDER_DEFUALT,
                    special_processing: '',
                    selections: '',
                    display_content: '',
                    acceptable_extensions: '',
                    toggle: true,
                    special_processing_list: constants.SPECIAL_PROCESSING,
                    check_method_list: constants.CHECK_METHOD,
                    item_role_list: constants.ITEM_ROLE_SPECIAL_PROCESSING_EMPTY,
                    item_role: constants.ITEM_ROLE_DEFAULT,
                    copy_id: '',
                    copy_item: false,
                    item_file_path: "",
                    status: 0,
                    calc_item: '',
                    calc_method: '',
                    number_calc: '',
                    calculation: [],
                    calc_flg: null,
                    related_items: [],
                    answer_action: [],
                    show_to_hide: false,
                    hide_to_show: false,
                    list_answer_show: [],
                    number_decimal: null,
                    rounding_type: null,
                    id_parent_related: null
                }
            },

             dataDefaultInitEmail(){
                const item_id = 'itemId_'+this.randomString(5)
                return {
                    item_id: item_id,
                    form_id: this.form_id,
                    item_group_name: '',
                    item_group_name_middle:'',
                    label_name: 'ユニークキー（メールアドレス)',
                    check_method: [1,7],
                    range_from_digit: '',
                    range_from_size: '',
                    range_to_digit: '',
                    range_to_size: '',
                    input_method: 1,
                    display_order: constants.DISPLAY_ORDER_DEFUALT,
                    special_processing: 1,
                    selections: '',
                    display_content: '',
                    acceptable_extensions: '',
                    toggle: true,
                    special_processing_list: constants.SPECIAL_PROCESSING,
                    check_method_list: constants.CHECK_METHOD,
                    item_role_list: constants.ITEM_ROLE_SPECIAL_PROCESSING_EMPTY,
                    item_role: constants.ITEM_ROLE_DEFAULT,
                    copy_id: '',
                    copy_item: false,
                    item_file_path: "",
                    status: 0,
                    calc_item: '',
                    calc_method: '',
                    number_calc: '',
                    calculation: [],
                    calc_flg: null,
                    related_items: [],
                    answer_action: [],
                    show_to_hide: false,
                    hide_to_show: false,
                    list_answer_show: [],
                    number_decimal: null,
                    rounding_type: null,
                    id_parent_related: null
                }
            },
             dataDefaultInitPW(){
                const item_id = 'itemId_'+this.randomString(5)
                return {
                    item_id: item_id,
                    form_id: this.form_id,
                    item_group_name: '',
                    item_group_name_middle:'',
                    label_name: 'パスワード',
                    check_method: [1,4,8],
                    range_from_digit: PASSWORD_LENGTH.min,
                    range_from_size: '',
                    range_to_digit: PASSWORD_LENGTH.max,
                    range_to_size: '',
                    input_method: 1,
                    display_order: constants.DISPLAY_ORDER_DEFUALT,
                    special_processing: 2,
                    selections: '',
                    display_content: '',
                    acceptable_extensions: '',
                    toggle: true,
                    special_processing_list: constants.SPECIAL_PROCESSING,
                    check_method_list: constants.CHECK_METHOD,
                    item_role_list: constants.ITEM_ROLE_SPECIAL_PROCESSING_EMPTY,
                    item_role: constants.ITEM_ROLE_DEFAULT,
                    copy_id: '',
                    copy_item: false,
                    item_file_path: "",
                    status: 0,
                    calc_item: '',
                    calc_method: '',
                    number_calc: '',
                    calculation: [],
                    calc_flg: null,
                    related_items: [],
                    answer_action: [],
                    show_to_hide: false,
                    hide_to_show: false,
                    list_answer_show: [],
                    number_decimal: null,
                    rounding_type: null,
                    id_parent_related: null
                }
            },dataDefaultInitPhone(){
                const item_id = 'itemId_'+this.randomString(5)
                return {
                    item_id:item_id,
                    form_id: this.form_id,
                    item_group_name: '',
                    item_group_name_middle:'',
                    label_name: '電話番号',
                    check_method: [1,13],
                    range_from_digit: PASSWORD_LENGTH.min,
                    range_from_size: '',
                    range_to_digit: PASSWORD_LENGTH.max,
                    range_to_size: '',
                    input_method: 1,
                    display_order: constants.DISPLAY_ORDER_DEFUALT,
                    special_processing: 4,
                    selections: '',
                    display_content: '',
                    acceptable_extensions: '',
                    toggle: true,
                    special_processing_list: constants.SPECIAL_PROCESSING,
                    check_method_list: constants.CHECK_METHOD,
                    item_role_list: constants.ITEM_ROLE_SPECIAL_PROCESSING_EMPTY,
                    item_role: constants.ITEM_ROLE_DEFAULT,
                    copy_id: '',
                    copy_item: false,
                    item_file_path: "",
                    status: 0,
                    calc_item: '',
                    calc_method: '',
                    number_calc: '',
                    calculation: [],
                    calc_flg: null,
                    related_items: [],
                    answer_action: [],
                    show_to_hide: false,
                    hide_to_show: false,
                    list_answer_show: [],
                    number_decimal: null,
                    rounding_type: null,
                    id_parent_related: null
                }
            },
            deleteAItemForm(index) {
                if (this.form[index]['item_id'] && String(this.form[index]['item_id']).indexOf('itemId_') == -1) {
                    this.loading = true
                    this.$store.dispatch(FORM_ITEM_DELETE_BY_ID, {id:this.form[index]['item_id'],form_id : this.form[index]['form_id']}).then(data => {
                        this.loading = false
                        if (data.code === constants.STATUS_CODE.SUCCESS) {
                            const indexItem = this.listItemCalc.findIndex(item => item.item_id == this.form[index].item_id);
                            if (indexItem !== -1) {
                                this.form[index].calc_item = ''
                                this.listItemCalc.splice(indexItem, 1);
                            }
                            const indexRelatedItems = this.listQuestionShow.findIndex(item => item.item_id == this.form[index].item_id);
                            if (indexRelatedItems !== -1) {
                                this.form.map(element => {
                                    const indexForm = element.related_items.findIndex(itm=>itm == this.form[index].item_id)
                                    if(indexForm !== -1) {
                                        element.related_items.splice(indexForm, 1)
                                    }
                                    return element
                                });
                                this.listQuestionShow.splice(indexItem, 1);
                            }
                            this.form.splice(index,1)
                        }  else {
                            this.notifyError(data.message)
                        }
                    }).catch(e => {
                        this.loading = false
                        this.notifyError(e)
                    })
                }else {
                    const indexItem = this.listItemCalc.findIndex(item => item.item_id == this.form[index].item_id);
                    if (indexItem !== -1) {
                        this.form[index].calc_item = ''
                        this.listItemCalc.splice(indexItem, 1);
                    }
                    const indexRelatedItems = this.listQuestionShow.findIndex(item => item.item_id == this.form[index].item_id);
                    if (indexRelatedItems !== -1) {
                        this.form.map(element => {
                            const indexForm = element.related_items.findIndex(itm=>itm == this.form[index].item_id)
                            if(indexForm !== -1) {
                                element.related_items.splice(indexForm, 1)
                            }
                            return element
                        });
                        this.listQuestionShow.splice(indexItem, 1);
                    }
                    this.form.splice(index,1)
                }
                this.notifySuccess(this.$t('DELETE_SUCCESS'))
                if (this.totalItemForm > 20){
                    this.getFormItemById(this.form_id)

                }
            },
            confirmDeteleItem(index){
                let checkResetForm = 0
                checkResetForm=this.resetFormItem(this.form[index],checkResetForm)
                if (checkResetForm){
                    this.itemKey=index
                    this.isDeleteAItem = true
                }else {
                    this.deleteAItemForm(index)
                }
                for (let key in this.errors){
                    let textArray = key.split(".");
                    let i = parseInt(textArray[0]);
                    if (i === index){
                        delete this.errors[key];
                    }
                }
                let newErrors = {};
                for (let key in this.errors){
                    let textArray = key.split(".");
                    let i = parseInt(textArray[0]);
                    if (i > index){
                        newErrors[i-1+"."+textArray[1]] = this.errors[key];
                    }else{
                        newErrors[key] = this.errors[key];
                    }
                }
                this.errors = newErrors;
            },
            resetFormItem(values,checkResetForm){
                let listCheck = constants.LIST_CHECK_RESET_FORM
                Object.keys(values).forEach(function (key) {
                    if (listCheck.includes(key)) {
                        if (values[key]) {
                            checkResetForm++
                        }
                    } else if (key == 'check_method') {
                        if (values['check_method'].length) {
                            checkResetForm++
                        }
                    } else if (key == 'display_order') {
                        if (values['display_order'] != constants.DISPLAY_ORDER_DEFUALT) {
                            checkResetForm++
                        }
                    }

                });
                return checkResetForm;
            },
            deleteItem(){
                this.loading = true
                let key  = this.itemKey
                this.itemKey = ''
                this.deleteAItemForm(key)
                this.isDeleteAItem = false
                this.loading = false
            },
            checkEditItemLbName($data,check=false) {
                // if (this.arraySelected || Object.keys(this.arraySelected).length !== 0) {
                //     for (const [key, value] of Object.entries(this.arraySelected)) {
                //         if (value.some(subArray => subArray.includes($data.item_id))){
                //             return false
                //         }
                //     }
                // }

                if (check && ($data.special_processing == SPECIAL_PROCESSING[1].value || $data.special_processing == SPECIAL_PROCESSING[0].value || $data.special_processing == SPECIAL_PROCESSING[3].value)) {
                    return false
                }else if (this.disableForm) {
                    return false
                }
                return true;
            },
            checkEditItem($data,check=false) {
                if (check && ($data.special_processing == SPECIAL_PROCESSING[1].value || $data.special_processing == SPECIAL_PROCESSING[0].value || $data.special_processing == SPECIAL_PROCESSING[3].value)) {
                    return false
                }else if (this.disableForm) {
                    return false
                }
                return true;
            },
            mapData(data){
                if (!data[0].save_drap){
                    this.checkDrap =true

                }else {
                    this.checkDrap =false
                    this.updateFormLabelFormBase()
                }
                this.form = data.map(formItemDetail => {
                    let listData = {
                        item_id:formItemDetail.item_id,
                        form_id: formItemDetail.form_id,
                        item_group_name: formItemDetail.item_group_name,
                        item_group_name_middle: formItemDetail.item_group_name_middle,
                        label_name: formItemDetail.label_name,
                        check_method: formItemDetail.check_method,
                        range_from_digit: formItemDetail.range_from_digit,
                        range_from_size: formItemDetail.range_from_size,
                        range_to_digit: formItemDetail.range_to_digit,
                        range_to_size: formItemDetail.range_to_size,
                        input_method: formItemDetail.input_method,
                        display_order: formItemDetail.display_order,
                        special_processing: formItemDetail.special_processing,
                        selections: formItemDetail.selections,
                        display_content: formItemDetail.display_content,
                        acceptable_extensions: formItemDetail.acceptable_extensions,
                        toggle: true,
                        special_processing_list: [],
                        check_method_list: [],
                        item_role_list: constants.ITEM_ROLE,
                        item_role: formItemDetail.item_role?formItemDetail.item_role:constants.ITEM_ROLE_DEFAULT,
                        copy_id: formItemDetail.copy_id,
                        copy_item: formItemDetail.copy_item,
                        item_file_path: formItemDetail.item_file_path,
                        status:formItemDetail.status,
                        url_download_file: formItemDetail.url_download_file,
                        item_id_parent: formItemDetail.item_id_parent,
                        calc_item: formItemDetail.calc_item ?? '',
                        calc_method: formItemDetail.calc_method ?? '',
                        number_calc: formItemDetail.number_calc ?? '',
                        calculation: formItemDetail.calculation_decode ?? [],
                        calc_flg: formItemDetail.calc_flg ?? null,
                        related_items: formItemDetail.related_items_decode ?? [],
                        answer_action:  formItemDetail.answer_action_decode ?? [],
                        show_to_hide: formItemDetail.display_start == 1 ? true : false,
                        hide_to_show: formItemDetail.display_start == 2 ? true : false,
                        list_answer_show: formItemDetail.selections ? formItemDetail.selections.split(',') : [],
                        number_decimal: formItemDetail.number_decimal != null ? formItemDetail.number_decimal : null,
                        rounding_type: formItemDetail.rounding_type > 0 ? formItemDetail.rounding_type : null,
                        id_parent_related: formItemDetail.id_parent_related != null ? formItemDetail.id_parent_related : null
                    }
                    return listData
                })
                // this.form = [];
                // if (this.formCache.length > 0){
                //     let count = 0;
                //     while (count < 20){
                //         if (this.formCache.length === 0){
                //             break;
                //         }
                //         this.form.push(this.formCache.shift());
                //         count++;
                //     }
                // }
                // this.interval = setInterval(() => {
                //     if (this.formCache.length > 0){
                //         this.form.push(this.formCache.shift());
                //     }else{
                //         clearInterval(this.interval);
                //     }
                // }, 5);

            },updateFormLabelFormBase(){
                let newFormBase= this.form_base_select_drap.map(formBase => {
                    return {
                        label: formBase.value == this.form_id ? formBase.label+'(途中保存)' : (formBase.saveDrap == true ? formBase.label+'(途中保存)' : formBase.label),
                        value: formBase.value
                    }
                })
                this.form_base_select = newFormBase
            },
            changeSpacialProcessing(data){
                data.check_method=[];
                this.changeInputMethod(data)
                this.mappingCopy(data)
                data.item_role_list = constants.ITEM_ROLE
                if (data.special_processing == SPECIAL_PROCESSING[1].value){
                    data.range_from_digit = PASSWORD_LENGTH.min
                    data.range_to_digit = PASSWORD_LENGTH.max
                    data.check_method=[1,4,8]
                    data.copy_id =''
                    data.item_id_parent= {}
                    data.item_role = constants.ITEM_ROLE_KEY.USER
                }else if (data.special_processing == SPECIAL_PROCESSING[0].value){
                    data.check_method=[1,7]
                    data.item_role = constants.ITEM_ROLE_KEY.USER
                    data.item_id_parent= {}
                    data.copy_id =''
                } else if (data.special_processing == SPECIAL_PROCESSING[3].value){
                    data.check_method=[1,13]
                    data.item_role = constants.ITEM_ROLE_KEY.USER
                    data.item_id_parent= {}
                    data.copy_id =''
                } else if (data.special_processing == SPECIAL_PROCESSING[2].value){
                    data.item_role = constants.ITEM_ROLE_KEY.ADMIN
                }
                else {
                    data.range_from_digit = ''
                    data.range_to_digit = ''
                    data.item_role_list = constants.ITEM_ROLE_SPECIAL_PROCESSING_EMPTY
                    data.item_role = constants.ITEM_ROLE_KEY.USER
                };
            },
            mappingCopy(data) {
                let new_item_id_parent = []
                let vm = this;
                Object.keys(this.item_id_parent).forEach(function (key) {
                    if (vm.item_id_parent[key].input_method == data.input_method) {
                        let newKey = vm.item_id_parent[key].item_id
                        new_item_id_parent.push(vm.item_id_parent[key])
                    }
                });
                data.item_id_parent = new_item_id_parent
                data.copy_id = ''
            },
            convertCopyForm(data) {
                let formBase= this.form_base_Parent
                 let item_group_name = JSON.parse(formBase.item_group_names)[data.item_group_name] + ' - '
                 let item_group_name_middle = '';
                 if (data.item_group_name_middle) {
                     item_group_name_middle = JSON.parse(formBase.item_group_name_middle)[data.item_group_name_middle] + ' - '
                 }
                return item_group_name + item_group_name_middle + data.label_name
            },
            appendCoppy() {
                let status = ''
                let group = ''
                if (!this.coppy_flag_check) {
                    status = constants.COPY_ITEM_STATUS.group
                    group = this.coppy_item_group_id
                } else {
                    status = constants.COPY_ITEM_STATUS.group_middle
                    group = this.coppy_item_group_middle_id
                }
                this.popup_coppy = false
                this.loading = true

                this.$store.dispatch(FORM_ITEM_SAVE_CHANGE_TAB, {data:this.form}).then(data => {
                    this.loading = false
                    if (data.code === constants.STATUS_CODE.SUCCESS) {
                        this.$store.dispatch(COPY_GROUP, {form:this.form_id,status:status,group:group}).then(data => {
                            this.loading = false
                            if (data.code === constants.STATUS_CODE.SUCCESS) {
                                this.$router.push({ name: 'form-item-add', query: { ...{ tab: this.activeName,form_id: this.form_id  } }})
                                this.changeFormBase();
                                this.notifySuccess('コピーしました')
                                if (!this.coppy_flag_check) {
                                    this.coppy_item_group_id = ''
                                }else {
                                    this.coppy_item_group_middle_id = ''
                                }
                            }  else {
                                this.notifyError(data.message)
                            }
                            this.coppy_flag_check = false;
                        }).catch(e => {
                            this.loading = false
                            this.notifyError(e)
                        })

                    } else if (data.code === constants.STATUS_CODE.BAD_REQUEST) {
                        this.activeName = this.queryParam.tab ? parseInt(this.queryParam.tab) : 1
                        this.resetChangeTab()
                        this.errors = data.data
                        let firstErrors = Object.keys(this.errors )[0].split(".")
                        let indexError = firstErrors[0]
                        if ( this.$refs['scroolError'+indexError]){
                            this.$refs['scroolError'+indexError][0].scrollIntoView();
                        }
                        if (data.data.showPopup){
                            this.$bvModal.show('PopUPValidate')
                        }
                        return
                    }  else {
                        this.notifyError(data.message)
                    }
                }).catch(e => {
                    this.loading = false
                    this.notifyError(e)
                })
            },
            copyitemByGroupName() {
                if (!this.coppy_item_group_id  || this.coppy_item_group_id == '' || this.coppy_item_group_id == null || this.coppy_item_group_id == ' ' ) {
                    this.$notify({
                        title: '確認',
                        message: '必須項目を選択してください。',
                        type: 'warning'
                    });
                }else {
                    this.popup_coppy = true
                }

            },
            copyitemByGroupNameMiddle() {
                if (!this.coppy_item_group_middle_id  || this.coppy_item_group_middle_id == '' || this.coppy_item_group_middle_id == null || this.coppy_item_group_middle_id == ' ' ) {
                    this.$notify({
                        title: '確認',
                        message: '必須項目を選択してください。',
                        type: 'warning'
                    });
                }else {
                    this.coppy_flag_check = true;
                    this.popup_coppy = true
                }
            },
            async uploadFile(e,data){
                this.errorMessage = [];
                if (e.target.files.length){
                    let size = this.bytesToSize(e.target.files[0].size);
                    if (size <= 100){
                        var files = e.target.files || e.dataTransfer.files;
                        const dataPush = new FormData()
                        dataPush.append("item_file_path", files[0]);
                        dataPush.append("form_id", this.queryParam.form_id);
                        const update = await this.$store.dispatch(FORM_ITEM_UPLOAD_FILE, dataPush);
                        data.item_file_path = update.data.url
                        data.url_download_file = update.data.url_download_file
                        if (update.code == constants.STATUS_CODE.SUCCESS){
                            this.notifySuccess('ファイルのアップロードに成功しました')
                        }
                    }else{
                        this.notifyError('ファイルの容量が最大100MBでアップロードしてください。')
                    }
                }
                e.target.files = null;
            },
            bytesToSize(bytes) {
                let i = 2;
                return Math.round(bytes / Math.pow(1024, i), 2);
            },
            async downloadFile($url){
                const buildUrl = await this.$store.dispatch(BUILD_URL_DOWNLOAD_FILE,$url);
                let link = document.createElement('a');
                link.href = buildUrl;
                link.target = '_blank';
                document.body.appendChild(link);
                link.click();
            },
            showNAmeFile(item){
                if (item.item_file_path) {
                    let explode = item.item_file_path.split("/");
                    return explode[explode.length - 1]
                }
            },
            copyaItem(item) {
                const item_id = 'itemId_'+this.randomString(5)
                if (!constants.SPECIAL_PROCESSING_KEY_UNIQUE_PASS.includes(item.special_processing)) {
                    let formitemClone = Object.assign({}, item);
                    formitemClone.item_id = item_id
                    if ([3,4].includes(item.input_method)){
                        formitemClone.related_items =[[]]
                        formitemClone.answer_action =[[]]
                    }
                    formitemClone.status = 0
                    if (this.form.length == constants.QUANTITY_FORM_ITEM || this.form.length > constants.QUANTITY_FORM_ITEM) {
                        this.itemChangeTab = formitemClone
                        this.popup_change_tab = true
                    }else {
                        this.form.push(formitemClone);
                    }

                }
            },
            chooseFiles(){
                document.getElementById("fileUploadExcel").click()
            },
            async uploadFileExcel(event) {
                let thisFile = event.target.files ? event.target.files[0] : null;
                const reader = new FileReader();
                reader.readAsArrayBuffer(thisFile);
                const formGroupName = this.form_group_name;
                const itemGroupNameMiddleList = this.item_group_name_middle_list;
                let res = [];
                const regex = /\{([^}]+)\}/g;
                let mapItemCalculator = [];
                let mapItemRelated = [];
                const fieldValidate = ['field_12','field_15','field_18','field_21','field_24'];
                const fieldValidateFrom = ['field_13','field_16','field_19','field_22','field_25'];
                const fieldValidateTo = ['field_14','field_17','field_20','field_23','field_26'];
                reader.onload = (e) =>  {
                    const bstr = this.decodeShiftJIS(e.target.result);
                    const rows = this.csvToArray(bstr);
                    const lastRow = rows.length;
                    let i = 0;
                    if (this.form_base_selected.special_item == 0){
                        let findCalculator = rows.find(function (i){
                            return i.field_30 != '';
                        })
                        if(findCalculator){
                            this.notifyError(this.$t("FORM_NOT_SUPPORT_SPECIAL"))
                            return;
                        }
                    }
                    while (i < lastRow){
                        let row = this.dataDefault();
                        let thisRow = rows[i];
                        // row.form_id = thisRow.field_0;
                        let itemGroupName = '';
                        if (thisRow.field_1){
                            let keyItemGroupName = Object.keys(formGroupName).find(key => String(formGroupName[key].trim()) === String(thisRow.field_1));
                            if (keyItemGroupName){
                                itemGroupName = keyItemGroupName;
                            }

                        }
                        row.item_group_name = itemGroupName !== '' ? parseInt(itemGroupName):'';
                        let itemGroupNameMiddle = '';
                        if (thisRow.field_2 && itemGroupNameMiddleList){
                            let keyItemGroupNameMiddle = Object.keys(itemGroupNameMiddleList).find(key => String(itemGroupNameMiddleList[key].trim()) === String(thisRow.field_2));
                            if (keyItemGroupNameMiddle){
                                itemGroupNameMiddle = keyItemGroupNameMiddle;
                            }
                        }
                        row.item_group_name_middle = itemGroupNameMiddle !== '' ? parseInt(itemGroupNameMiddle):'';

                        row.label_name = thisRow.field_3?String(thisRow.field_3):'';
                        row.copy_id = thisRow.field_4?parseInt(thisRow.field_4):'';
                        row.copy_id = thisRow.field_4?parseInt(thisRow.field_4):'';
                        row.input_method = thisRow.field_5?parseInt(thisRow.field_5):'';
                        row.display_order = thisRow.field_6?parseInt(thisRow.field_6):constants.DISPLAY_ORDER_DEFUALT;
                        row.special_processing = thisRow.field_7?parseInt(thisRow.field_7):'';
                        row.selections = thisRow.field_8?String(thisRow.field_8):'';
                        row.display_content = thisRow.field_9?String(thisRow.field_9.replace(/\(\*\*\)/g, '\n')):'';
                        row.acceptable_extensions = thisRow.field_10?String(thisRow.field_10):'';
                        row.item_role = thisRow.field_11?parseInt(thisRow.field_11):constants.ITEM_ROLE_DEFAULT;
                        row.show_to_hide= thisRow.field_31 == '1' ? true : false,
                        row.hide_to_show= thisRow.field_31 == '2' ? true : false,
                        row.answer_action= thisRow.field_32 ? JSON.parse(thisRow.field_32.replace(/'/g, '"')) : '',
                        row.number_decimal= thisRow.field_34 ? thisRow.field_34 : null,
                        row.rounding_type= thisRow.field_35 ? parseInt(thisRow.field_35) : null,
                        row.item_file_path = thisRow.field_36 ? thisRow.field_36 : '',

                        this.mappingCopy(row)
                        switch (row.input_method) {
                            case 1: {
                                row.special_processing_list = constants.SPECIAL_PROCESSING
                                row.check_method_list = this.newListCheckMethod(constants.CHECK_METHOD_INPUT_TEXT)
                                break;
                            }case 2: {
                                row.special_processing_list = constants.SPECIAL_PROCESSING_CUSTOM
                                row.check_method_list = this.newListCheckMethod(constants.CHECK_METHOD_DEFAULT)
                                break;
                            }
                            case 3: {
                                row.special_processing_list = constants.SPECIAL_PROCESSING_CUSTOM
                                row.check_method_list = this.newListCheckMethod(CHECK_METHOD_INPUT_CHECKBOK_)
                                break;
                            }
                            case 4: {
                                row.special_processing_list = constants.SPECIAL_PROCESSING_CUSTOM
                                row.check_method_list = this.newListCheckMethod(CHECK_METHOD_INPUT_CHECKBOK_)
                                break;
                            }
                            case 5: {
                                row.special_processing_list = constants.SPECIAL_PROCESSING_CUSTOM
                                row.check_method_list = this.newListCheckMethod(CHECK_METHOD_INPUT_FILE)
                                break;
                            }
                            case 6: {
                                row.special_processing_list = constants.SPECIAL_PROCESSING_CUSTOM
                                row.check_method_list = []
                                break;
                            }
                            case 7: {
                                row.special_processing_list = constants.SPECIAL_PROCESSING_CUSTOM
                                row.check_method_list = this.newListCheckMethod(CHECK_METHOD_INPUT_CHECKBOK_)
                                break;
                            }
                            case 8: {
                                row.special_processing_list = constants.SPECIAL_PROCESSING_CUSTOM
                                row.check_method_list = []
                                break;
                            }
                            default: {
                                row.special_processing_list = constants.SPECIAL_PROCESSING_CUSTOM
                                row.check_method_list = this.newListCheckMethod(constants.CHECK_METHOD_DEFAULT_ALL)
                            }
                        }


                        for (let j = 0; j < fieldValidate.length; j++){
                            let v = fieldValidate[j];
                            if (thisRow[v]){
                                let valueCheck = parseInt(thisRow[v]);
                                row.check_method.push(valueCheck)
                            }
                        }

                        for (let j = 0; j < fieldValidateFrom.length; j++){
                            let v = fieldValidateFrom[j];
                            if (thisRow[v]){
                                let valueCheck = parseInt(thisRow[v]);
                                row.range_from_digit = valueCheck;
                                break;
                            }
                        }

                        for (let j = 0; j < fieldValidateTo.length; j++){
                            let v = fieldValidateTo[j];
                            if (thisRow[v]){
                                let valueCheck = parseInt(thisRow[v]);
                                row.range_to_digit = valueCheck;
                                break;
                            }
                        }

                        if(thisRow.field_30){
                            mapItemCalculator.push({
                                object : row,
                                calculator : thisRow.field_30,

                            });
                        }
                        if([3,4].includes(parseInt(thisRow.field_5)) ){
                            if (thisRow.field_33){
                                mapItemRelated.push({
                                    object : row,
                                    related_items : thisRow.field_33,
                                });
                            }else {
                                row.related_items = [[]]
                                row.answer_action = [[]]
                            }
                        }
                        if (row.label_name != '' && row.form_id > 0){
                            this.form.push(row);
                        }
                        i++;
                    }
                    for (let j = 0; j < mapItemRelated.length;j++){
                        let related = mapItemRelated[j].related_items
                        var relatedArray = JSON.parse(related.replace(/'/g, '"'));
                        var outputRelatedArray=relatedArray.map((item) => {
                            return item.map((item2) => {
                                let itemObject = this.form.find(function (i){
                                    if (i.label_name == item2){
                                        return true;
                                    }
                                });
                                if (itemObject){
                                    return itemObject.item_id;
                                }else return ''
                            })
                        })
                        this.arraySelected[mapItemRelated[j].object.item_id] = outputRelatedArray
                        mapItemRelated[j].object.related_items = outputRelatedArray;
                    }
                    for (let i = 0; i < mapItemCalculator.length;i++){
                        let calculator = mapItemCalculator[i].calculator;
                        let inputItems = calculator.match(regex);
                        let resultString = calculator.replace(/ /g,"___");

                        for (let i = 0;i < inputItems.length;i++){
                            let inputCache = inputItems[i].replace(/ /g,"___");
                            resultString = resultString.replace(inputCache,inputItems[i]);
                        }
                        let calArr = resultString.split('___');
                        let calArrEndPoint = [];
                        for (let i = 0; i < calArr.length;i++){
                            let item = calArr[i];
                            if(item.includes('{')){
                                item = item.replace('{','').replace('}','');
                                let itemObject = this.form.find(function (i){
                                    if (i.label_name == item){
                                      return true;
                                    }
                                });
                                calArrEndPoint.push({
                                    id: itemObject.item_id,
                                    type: 1,
                                    label: itemObject.label_name,
                                })
                            }else if(constants.CALC_METHOD_FULL_SIZE.includes(item)){
                                calArrEndPoint.push({
                                  type: 2,
                                  label: Object.keys(constants.CALC_MAP).find(key => constants.CALC_MAP[key] === item),
                                })
                            } else {
                                calArrEndPoint.push({
                                  type: 3,
                                  label: item,
                                })
                            }
                        }
                        mapItemCalculator[i].object.calculation = calArrEndPoint;
                    }
                }
                document.getElementById("fileUploadExcel").value = '';
            },
            handleClick (tab, event) {
                if (this.form.length == 0 || this.disableForm) {
                    this.loading = true
                    this.activeName = tab.name
                    this.$router.push({ name: 'form-item-add', query: { ...{ tab: this.activeName,form_id: this.form_id  } }})
                    this.changeFormBase();
                }else {
                    this.popup_change_tab=true;
                    this.flagChangeTab = true
                    // this.saveChangeTab();
                }
            },
            closeDialogChangeTab(){
                this.popup_change_tab=false;
                this.itemChangeTab = {}
                this.activeName = this.queryParam.tab ? parseInt(this.queryParam.tab) : 1
                this.flagChangeTab = false;
            },
            addTab() {
                this.saveChangeTab()
            },
            saveChangeTab() {
                this.loading = true
                this.$store.dispatch(FORM_ITEM_SAVE_CHANGE_TAB, {data:this.form}).then(data => {
                    this.loading = false
                    if (data.code === constants.STATUS_CODE.SUCCESS) {
                        if (!this.flagChangeTab) {
                                if (Object.keys(this.itemChangeTab).length) {
                                    this.form_append = this.itemChangeTab
                                }else {
                                    this.form_append = this.dataDefault()
                                }
                            this.resetChangeTab()
                            this.totalItemForm = data.data
                            this.activeName = this.totalItemForm
                            this.$router.push({ name: 'form-item-add', query: { ...{ tab: this.activeName,form_id: this.form_id  } }})
                            this.changeFormBase();

                        }else {
                            this.$router.push({ name: 'form-item-add', query: { ...{ tab: this.activeName,form_id: this.form_id  } }})
                            this.changeFormBase();
                            this.resetChangeTab()
                        }

                    } else if (data.code === constants.STATUS_CODE.BAD_REQUEST) {
                        this.activeName = this.queryParam.tab ? parseInt(this.queryParam.tab) : 1
                        this.resetChangeTab()
                        this.errors = data.data
                        let firstErrors = Object.keys(this.errors )[0].split(".")
                        let indexError = firstErrors[0]
                        if ( this.$refs['scroolError'+indexError]){
                            this.$refs['scroolError'+indexError][0].scrollIntoView();
                        }
                        if (data.data.showPopup){
                            this.$bvModal.show('PopUPValidate')
                        }
                        return
                    }  else {
                        this.notifyError(data.message)
                    }
                }).catch(e => {
                    this.loading = false
                    this.notifyError(e)
                })
            },
            resetChangeTab() {
                this.popup_change_tab=false;
                this.flagChangeTab = false;
                this.itemChangeTab = {}
            },
            getIncrement(data, index) {
                let increment = ((this.activeName-1)* constants.QUANTITY_FORM_ITEM) +index + 1
                if(!data.item_group_name){
                    if (!data.display_order_select) {
                        data.display_order = increment
                        data.display_order_select = 1
                    }
                }
                return increment;
            },
            checkReload(event) {
                if ((event.keyCode == 116)) {
                    event.preventDefault();
                    if (this.resetForm()) {
                        this.confirm_reload = true
                        return false;
                    } else this.enterByKeyCode()
                }
                if ((event.ctrlKey || event.metaKey) && event.keyCode == 82) {
                    event.preventDefault();
                    if (this.resetForm()) {
                        this.confirm_reload = true
                        return false;
                    } else this.enterByKeyCode()
                }
            },
            arrayAsString(formItem) {
                const values = formItem.map(item => item.label);
                const characterMap = constants.CALC_MAP;
                for (let i = 0;i < values.length;i++){
                  if (characterMap.hasOwnProperty(values[i])) {
                    values[i] = characterMap[values[i]];
                  }
                }
                const result = values.join(' ');
                return result
            },
            addCalcItem(formItem,value,type) {
                const itemId = this.listItemCalc.find(item => item.item_id == value);
                const lastItem = formItem.calculation[formItem.calculation.length - 1]
                let obj = {}
                let itemLabel = {}
                if(type == constants.CALCULATION_TYPE.ITEM) {
                    itemLabel = this.form.find(item => item.item_id == value)
                    obj = {
                        id : value,
                        type : type,
                        label : itemId.label_name
                    }
                } else {
                    obj = {
                        type : type,
                        label : value
                    }
                }
                formItem.calc_flg = lastItem ? formItem.calc_flg : null
                if(formItem.calc_flg != type || formItem.calc_flg == null) {
                    switch (type) {
                        case constants.CALCULATION_TYPE.ITEM:
                            if (lastItem && (lastItem.label == itemLabel?.label_name || lastItem.type == constants.CALCULATION_TYPE.ITEM || lastItem.type == constants.CALCULATION_TYPE.VALUE)) {
                                this.$notify.error('項目・数字が連続に置いています。ご確認ください。');
                            } else {
                                formItem.calculation.push(obj)
                            }
                            break;
                        case constants.CALCULATION_TYPE.CALCULATION:
                            if (['(',')'].indexOf(value) == -1 && lastItem && lastItem.label == value) {
                                this.$notify.error('演算子が連続に置いています。ご確認ください。');
                            } else {
                                formItem.calculation.push(obj)
                            }
                            break;
                    
                        case constants.CALCULATION_TYPE.VALUE:
                            if (lastItem && (lastItem.label == value || lastItem.type == constants.CALCULATION_TYPE.ITEM || lastItem.type == constants.CALCULATION_TYPE.ITEM)) {
                                this.$notify.error('項目・数字が連続に置いています。ご確認ください。');
                            } else {
                                formItem.calculation.push(obj)
                            }
                            break;
                    
                        default:
                            break;
                    }
                    formItem.calc_flg = type
                } else {
                    switch (type) {
                        case constants.CALCULATION_TYPE.ITEM:
                            if (lastItem && (lastItem.label == itemLabel.label_name || lastItem.type == constants.CALCULATION_TYPE.ITEM || lastItem.type == constants.CALCULATION_TYPE.VALUE)) {
                                this.$notify.error('項目・数字が連続に置いています。ご確認ください。');
                            } else {
                                formItem.calculation.push(obj)
                            }
                            break;
                        case constants.CALCULATION_TYPE.CALCULATION:
                            if (['(',')'].indexOf(value) == -1 && (lastItem.label == value || lastItem.label != value ) && ['+','-','X',':'].includes(lastItem.label) == true) {
                                this.$notify.error('演算子が連続に置いています。ご確認ください。');
                            } else {
                                formItem.calculation.push(obj)
                            }
                            break;
                    
                        case constants.CALCULATION_TYPE.VALUE:
                            if (lastItem && (lastItem.label == value || lastItem.type == constants.CALCULATION_TYPE.ITEM || lastItem.type == constants.CALCULATION_TYPE.VALUE)) {
                                this.$notify.error('項目・数字が連続に置いています。ご確認ください。');
                            } else {
                                formItem.calculation.push(obj)
                            }
                            break;
                    
                        default:
                            break;
                    }
                }
            },
            removeCalcItem(formItem) {
                formItem.calculation.pop()
            },
            filterCalcItem(formItem) {
                    const existingItem = this.listItemCalc.find(item => item.item_id == formItem.item_id);
                    const checkCalc = (
                        (formItem.input_method == 1 &&
                            formItem.check_method.some(check => check == 3) &&
                            formItem.hide_to_show == false &&
                            formItem.show_to_hide == false)
                        ||
                        (formItem.input_method == 9 &&
                            formItem.hide_to_show == false &&
                            formItem.show_to_hide == false)
                    )
                    if (!existingItem) {
                        if(checkCalc && formItem.label_name != '') {
                            const newItem = {
                                "item_id": formItem.item_id,
                                "label_name": formItem.label_name,
                                "input_method": formItem.input_method,
                                "item_check_relation": []
                            }
                            formItem.check_method.forEach(item => {
                                const newObject = {
                                    "form_id": formItem.form_id,
                                    "item_id": formItem.item_id,
                                    "check_method": item
                                }
                                newItem.item_check_relation.push(newObject);
                            });
                            this.listItemCalc.push(newItem)              
                        }
                    } else {
                        if(!checkCalc || formItem.label_name == '') {
                            const index = this.listItemCalc.findIndex(item => item.item_id == formItem.item_id);
                            if (index !== -1) {
                                this.listItemCalc.splice(index, 1);
                            }
                        }
                        if(checkCalc == true && formItem.label_name != '') {
                            const index = this.listItemCalc.findIndex(item => item.item_id == formItem.item_id);
                            this.listItemCalc[index].label_name = formItem.label_name
                        }
                    }
                    //------------------------
                    const existingItem1 = this.listQuestionShow.find(item => item.item_id == formItem.item_id);
                    if (!existingItem1) {
                        if(formItem.label_name != '' && formItem.label_name != null && (formItem.show_to_hide == true || formItem.hide_to_show == true)) {
                            const newItem = {
                                "item_id": formItem.item_id,
                                "label_name": formItem.label_name,
                                "input_method": formItem.input_method,
                                "item_check_relation": [],
                                "selected" : false
                            }
                            formItem.check_method.forEach(item => {
                                const newObject = {
                                    "form_id": formItem.form_id,
                                    "item_id": formItem.item_id,
                                    "check_method": item
                                }
                                newItem.item_check_relation.push(newObject);
                            });    
                            this.listQuestionShow.push(newItem)              
                        }
                    } else {
                        if(formItem.label_name == '' || formItem.label_name == null || (formItem.show_to_hide == false && formItem.hide_to_show == false)) {
                            const index = this.listQuestionShow.findIndex(item => item.item_id == formItem.item_id);
                            if (index !== -1) {
                                this.listQuestionShow.splice(index, 1);
                                this.form.forEach(element => {
                                    if (element.related_items.length){
                                        var isInArray = element.related_items.some(subArray => subArray.includes(formItem.item_id));
                                        if (isInArray){
                                            let newArr = element.related_items.map(subArray => subArray.filter(element => element != formItem.item_id));
                                                element.related_items = newArr
                                        }

                                    }
                                    // if(element.related_items.includes(formItem.item_id)) {
                                    //     let ind = element.related_items.findIndex(itm => itm == formItem.item_id)
                                    //     element.related_items.splice(ind, 1);
                                    // }
                                });
                            }
                        }
                        if(formItem.label_name != '' && formItem.label_name != null && (formItem.show_to_hide == true || formItem.hide_to_show == true)) {
                            const index = this.listQuestionShow.findIndex(item => item.item_id == formItem.item_id);
                            this.listQuestionShow[index].label_name = formItem.label_name
                        }
                    }
            },
            showHide(formItem,type) {
                switch (type) {
                    case 1:
                        if(formItem.show_to_hide == true) {
                            formItem.hide_to_show = false
                            // formItem.related_items = [[]]
                            // formItem.answer_action = [[]]
                            // if (this.arraySelected[formItem.item_id]){
                            //     delete this.arraySelected[formItem.item_id]
                            // }
                        }
                        break;
                
                    case 2:
                        if(formItem.hide_to_show == true) {
                            formItem.show_to_hide = false
                            // formItem.related_items = [[]]
                            // formItem.answer_action = [[]]
                            // if (this.arraySelected[formItem.item_id]){
                            //     delete this.arraySelected[formItem.item_id]
                            // }
                        }
                        break;
                
                    default:
                        break;
                }
                this.$forceUpdate()
                this.filterCalcItem(formItem)
            },
            changeRelatedItem(value,formItem,index) {
                if (value){
                    const hasEmptyString = value.includes(' ');
                    if (hasEmptyString) {
                        formItem.related_items[index] = []
                        delete this.arraySelected[formItem.item_id]
                    } else {
                        const isAllNull = value.every(value => value === null);

                        if (!isAllNull) {
                            if (!this.arraySelected[formItem.item_id]) {
                                this.arraySelected[formItem.item_id] = []
                            }
                            this.arraySelected[formItem.item_id][index] = value
                        } else {
                            if (formItem.related_items.length == 1 && formItem.related_items[0].every(value => value === null)) {
                                delete this.arraySelected[formItem.item_id]
                            } else {
                                delete this.arraySelected[formItem.item_id][index];
                            }
                        }
                    }
                }
            },
            changeAnswerAction(value,formItem,index) {
                const hasEmptyString = value.includes(' ');
                if (hasEmptyString) {
                    formItem.answer_action[index] = []
                }
            },
            addAnswerShow(formItem){
                formItem.list_answer_show = formItem.selections.split(',');
                if (formItem.list_answer_show[formItem.list_answer_show.length - 1] == "") {
                    formItem.list_answer_show.pop();
                }
                let new_action_item = formItem.answer_action.map((action_item) => {
                  return  action_item.filter((value) => formItem.list_answer_show.includes(value));
                })
                 formItem.answer_action = new_action_item
            },
            disableListQuestion(item,formItem){
                if (item.item_id == formItem.item_id){
                    return true
                }
                let keyCheck= 0
                for (const [key, value] of Object.entries(this.arraySelected)) {
                    if (key != formItem.item_id) {
                        if (value.length){
                            var isInArray = value.some(subArray => subArray.includes(item.item_id));
                            var isInArray1 = value.some(subArray => subArray.includes(formItem.item_id));
                            if (isInArray1){
                                keyCheck = key
                            }
                            if (isInArray){
                                keyCheck = key
                                return true
                            }
                        }
                    }else {
                        var isInArray = formItem.related_items.some(subArray => subArray.includes(item.item_id));
                        if (isInArray){
                            return true
                        }
                    }
                }
                if (keyCheck == item.item_id){
                    return  true
                }
                let recursionFormItemArr = this.recursionFormItem(formItem.item_id)
                if (recursionFormItemArr.includes(item.item_id)){
                    return true
                }
                return  false
            },
            disableDisplayStart(formItem){
                let check = false
                this.form.forEach(element => {
                    if(element.calculation.some(itm => itm.id == formItem.item_id)) {
                        check = true
                    }
                });
                for (const [key, value] of Object.entries(this.arraySelected)) {
                    if (value.length) {
                        var isInArray = value.some(subArray => subArray.includes(formItem.item_id));
                        if (isInArray) {
                            check = true
                            return check
                        }
                    }
                }
                return check
            },
            validNumber($event,item){
                let text =  $event.replace(/([^0-9])+/i, "")
                item.number_decimal = text;
            },
            validCalcNumber($event,item){
                let text =  $event.replace(/([^0-9])+/i, "")
                item.number_calc = text;
            },
            addNewItemRelatedItem(item){
                item.related_items.push([])
                item.answer_action.push([])
            },
            deleteItemRelatedItem(formItem,index){
                if (formItem.related_items.length == 1 && formItem.related_items[0].every(value => value === null)){
                    delete this.arraySelected[formItem.item_id]
                }else {
                    delete this.arraySelected[formItem.item_id][index];
                }
                formItem.related_items.splice(index,1)
                formItem.answer_action.splice(index,1)
            },
            disableFormSelect(formItem){
                if (formItem.show_to_hide == true || formItem.hide_to_show == true){
                    formItem.related_items = [[]]
                    formItem.answer_action = [[]]
                    return true
                }
                return  false
            },
            recursionFormItem(item_id = null, listCheck = []) {
                if (!this.arraySelected || Object.keys(this.arraySelected).length === 0) {
                    return [];
                }
                let check = false;
                if (item_id == 'null'){
                    return listCheck ;
                }else {
                    for (const [key, value] of Object.entries(this.arraySelected)) {
                        var isInArray = value.some(subArray => subArray.includes(this.isNumericString(item_id) ? parseInt(item_id) : item_id));
                        if (isInArray) {
                            check = key;
                            listCheck.push(check);
                            break
                        }
                    }
                    if (check !== false) {
                        return this.recursionFormItem(check, listCheck);
                    } else {
                        return this.recursionFormItem('null',listCheck);
                    }
                }
            },
            isNumericString(str) {
                return /^\d+$/.test(str);
            }


        },
        updated() {
            let check = this.resetForm()
            let response = this.listRedirect(check)
        }

    }
</script>
